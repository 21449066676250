<template>
  <div>
    <popup
      :message="alertErrMessage"
      v-if="isAlertErr"
      :isError="isError"
      @close="isAlertErr = false"
    />
    <div class="hero">
      <div class="content">
        <h1>
          <i style="color: #ffde59">One-Stop Solution</i> Optimasi Bisnis
          Transformasi Digital Berkelanjutan
        </h1>
        <p>
          Perluas skala bisnis perusahaan Anda dalam dinamika transformasi
          digital melalui layanan Corporate Service HiColleagues sebagai mitra
          akselerasi digital terpercaya & kredibel
        </p>
        <div class="btn-cta">
          <button class="now" @click="scrollToSection('corporate_form')">
            DAFTAR SEKARANG
          </button>
          <button class="call" @click="sendToWa">KONSULTASI GRATIS</button>
        </div>
      </div>
      <div class="card-img">
        <img
          src="/img/corporate/corporate.png"
          width="520"
          height=""
          alt=""
          style="border-radius: 0 0 0 80px"
        />
      </div>
    </div>
    <div class="creditbility">
      <div class="partner">
        <h1>HiColleagues telah Dipercaya Perusahaan Ternama</h1>
        <div class="card" style="overflow-x: none !important">
          <div class="left-fade"></div>
          <div class="right-fade"></div>
          <div v-for="(partner, index) in partners" :key="index">
            <div class="logo-card-partner">
              <!-- <img src="/img/partner/astrapay.png" width="160" /> -->
              <img :src="partner.img_url_web" width="160" />
            </div>
          </div>
        </div>
      </div>
      <div class="statistics">
        <div class="card">
          <div v-for="(statistic, index) in statistics" :key="index">
            <div class="card-statistic">
              <components :is="statistic.img" width="40" height="40" ></components>
              <h3>{{ statistic.number }}</h3>
              <p>{{ statistic.desc }}</p>
            </div>
          </div>
        </div>
        <div class="desc">
          <h3>
            Sejak 2020 HiColleagues Berkomitmen sebagai Mitra Corporate Kredibel
            & Terpercaya
          </h3>
          <p>
            Inovasi & Layanan HiColleagues terbukti memberikan dampak signifikan
            terhadap skala pertumbuhan perusahaan mitra
          </p>
        </div>
      </div>
    </div>
    <div class="key-feature">
      <h1>Layanan Akselerasi Transformasi Digital Kami</h1>
      <div
        class="card-feature"
        v-for="(keyFeature, index) in keyFeatures"
        :key="index"
      >
        <div class="content">
          <h2>
            {{ keyFeature.title }}
          </h2>
          <p>
            {{ keyFeature.desc }}
          </p>
          <button
            style="background-color: #162ca2; color: white"
            @click="$router.push(keyFeature.link)"
          >
            {{keyFeature.link ? 'PELAJARI SELENGKAPNYA' : 'COMING SOON' }}
          </button>
        </div>
        <img
          class="card-image"
          style="width: 50%; height: 110%"
          :src="keyFeature.image"
          alt=""
        />
      </div>
    </div>
    <div class="list-of-acceleration">
      <h1>Program Akselerasi Digital</h1>
      <p>
        Rekomendasi program pelatihan untuk akselerasi skill talenta digital dan
        peningkatan performa karir cemerlang
      </p>
      <div class="card">
        <div
          class="items"
          v-for="(item, index) in listAcceleration"
          :key="index"
          @click="
            $router.push(`/corporate/acceleration/detail/${item.slug_name}`)
          "
        >
          <div style="height: 200px">
            <img
              :src="item.image_url"
              alt=""
              style="width: 100%; height: 200px; border-radius: 20px 20px 0 0"
            />
            <div class="label">
              <img :src="item.collaboration_feed" alt="" style="width: 80%" />
            </div>
          </div>
          <div class="content">
            <h2 style="height: 50px">{{ item.name }}</h2>
            <div class="category">
              <div style="background-color: #f1f6ff" v-if="item.type">
                <p>{{ item.type }}</p>
              </div>
              <div style="background-color: #faeceb" v-if="item.class_type">
                <p>{{ item.class_type }}</p>
              </div>
              <div style="background-color: #faf6e7" v-if="item.method">
                <p>{{ toMethode(item.method) }}</p>
              </div>
            </div>
            <p class="description-class">{{ item.description }}</p>
            <div style="height: 60px">
              <p
                style="color: #b80f0a; font-size: 16px; margin: 0"
                v-if="item.is_discount"
              >
                <del>{{ item.price | toCurrency }}</del>
              </p>
              <h3 style="color: #162ca2; margin: 0" v-if="!item.is_discount">
                {{ item.price | toCurrency }}
              </h3>
              <h3 style="color: #162ca2; margin: 0" v-else>
                {{ toDiscount(item) }}
              </h3>
            </div>
          </div>
          <div class="due-date">
            <DateIc2 />
            <span style="font-size: 15px">{{
              item.registration_close_date | formatDate
            }}</span>
          </div>
        </div>
      </div>
      <button
        style="background-color: #162ca2; color: white"
        @click="$router.push('/corporate/acceleration')"
      >
        LIHAT SELENGKAPNYA
      </button>
    </div>
    <div class="unique-selling-proposition">
      <h1 class="text-center poppins-font usp-title">
        Mengapa memilih HiColleagues
        <div class="content">
          <div class="row">
            <div class="col-lg-4 items" v-for="(item, idx) in usps" :key="idx">
              <div class="card text-start" style="background-color: white">
                <img :src="item.img" alt="" width="60" height="60" />
                <div class="label">
                  <p class="sub-title">{{ item.name }}</p>
                  <p class="description">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </h1>
    </div>

    <Testimony
      title="Testimoni Kepuasan Klien Kami"
      :testimonies="testimonies"
    />
    <div class="form-konsultasi" id="corporate_form">
      <div class="header text-center">
        <h1>Formulir Pendaftaran Konsultasi</h1>
      </div>
      <div class="sub-heading mt-1 text-center">
        <p>
          Langkah tepat optimasi bisnis melalui layanan Corporate Service
          bersama HiColleagues
        </p>
        <v-form class="text-start" style="margin-top: 60px">
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="company"
                >Nama Perusahaan<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="company"
                outlined
                placeholder="Masukkan Nama Perusahaan"
                v-model="form.corporate_name"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="website">Website Perusahaan</label>
              <v-text-field
                class="form-input text-form"
                name="website"
                outlined
                v-model="form.web_url"
                placeholder="Masukkan Alamat Website Perusahaan"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="pic-name"
                >Nama Lengkap PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="pic-name"
                v-model="form.full_name"
                outlined
                placeholder="Masukkan Nama Lengkap PIC"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="occupation">Okupasi PIC</label>
              <v-text-field
                class="form-input text-form"
                name="occupation"
                v-model="form.job_pic"
                outlined
                placeholder="Masukkan Okupasi PIC"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="email"
                >Email PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="email"
                v-model="form.email"
                outlined
                placeholder="Masukkan Email PIC"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="phone-number"
                >No. Whatsapp PIC<span style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-text-field
                class="form-input text-form"
                name="phone-number"
                outlined
                placeholder="Masukkan No. Whatsapp PIC"
                type="number"
                v-model="form.phone_number"
                hide-spin-buttons
              >
                <template v-slot:prepend-inner>
                  <v-row class="d-flex align-center mt-0 ml-7">
                    <img
                      src="/img/other/flag-indonesia.png"
                      alt=""
                      width="30"
                      height="20"
                      style="box-shadow: 2px 2px 3px 1px rgba(13, 28, 113, 0.1)"
                    />
                    <span style="color: #575966" class="mx-3">+62</span>
                  </v-row>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label class="form-label" for="program-category"
                >Proyeksi Kolaborasi/Kemitraan<span
                  style="color: rgba(184, 15, 10, 1)"
                  >*</span
                ></label
              >
              <v-select
                class="form-input text-form"
                outlined
                :items="categories"
                :item-text="'name'"
                :item-value="'value'"
                v-model="form.category"
                placeholder="Pilih Kategori Program Kolaborasi"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center mt-3">
            <v-checkbox class="form-input custom-checkbox" v-model="is_confirm">
              <template v-slot:label>
                <p class="label-checkbox">
                  Dengan mengirim formulir ini saya menyetujui<a
                    href="/term-condition"
                  >
                    Syarat & Ketentuan </a
                  >untuk melakukan kemitraan/kolaborasi bersama HiColleagues.
                </p>
              </template>
            </v-checkbox>
          </v-row>
          <v-row class="d-flex justify-center align-center mt-6">
            <v-btn
              elevation="0"
              :class="`btn-kirim ${is_confirm ? 'confirm' : 'reject'}`"
              @click="onSubmit"
              >KIRIM
            </v-btn>
          </v-row>
        </v-form>
      </div>
    </div>
    <div class="faq" style="margin-top: 60px">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header" style="margin-bottom: 20px;">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: -10px;">
            <v-expansion-panels
              flat
              class="faq-card"
              style="padding: 20px; background-color: #F1F6FF;"
            >
              <v-expansion-panel v-for="(item, index) in questions"
              :key="index" style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header
                  disable-icon-rotate
                  @click="openFaq(index)"
                >
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow  style="margin-right: 35px;"/>
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active" style="margin-right: 35px;"/>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- <div class="btn-stoke-blue" @click="$router.push('/faq')">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";
import DateIc2 from "@/assets/icons/DateIc2.vue";
import Testimony from "../../Testimony.vue";
import Popup from "@/components/others/Popup.vue";
import BuildIc from '@/assets/icons/corporate/BuildIc.vue';
import BookIc from '@/assets/icons/corporate/BookIc.vue';
import PeopleIc from '@/assets/icons/corporate/PeopleIc.vue';
import PeopleGroupIc from '@/assets/icons/corporate/PeopleGroupIc.vue';

export default {
  components: {
    Arrow,
    DateIc2,
    Testimony,
    Popup,
    BuildIc,
    PeopleGroupIc,
    PeopleIc,
    BookIc
  },
  data() {
    return {
      partners: [],
      statistics: [
        {
          img: BuildIc,
          number: "30+",
          desc: "Perusahaan top di Indonesia telah puas terhadap layanan HiColleagues",
        },
        {
          img: BookIc,
          number: "50+",
          desc: "Program pelatihan konstruktif & kredibel untuk kebutuhan perusahaan mitra",
        },
        {
          img: PeopleGroupIc,
          number: "1.000+",
          desc: "Peserta telah menyelesaikan & puas terhadap pelatihan HiColleagues",
        },
        {
          img: PeopleIc,
          number: "100+",
          desc: "Tenaga kerja berkualitas telah ditempatkan di perusahaan mitra ternama",
        },
      ],
      keyFeatures: [
        {
          title: "Corporate Collaboration",
          desc: "Kolaborasi efektif dalam bersinergi dan berinovasi bersama perusahaan mitra dalam mencapai tujuan bisnis melalui program-program unggulan seperti Workshop, Webinar, Mini Bootcamp, Bootcamp, dan General Training yang dirancang untuk kebutuhan masyarakat luas atau segmentasi tertentu.",
          image: "/img/corporate/mask1.png",
          link: "/corporate/collaboration", // 
        },
        {
          title: "Corporate Training",
          desc: "Program kemitraan pelatihan dalam pengembangan keterampilan tenaga kerja/karyawan perusahaan mitra melalui Upskilling dan New-Skilling yang tersedia dalam 2 kategori pelatihan yakni General Corporate Training dan Certified Corporate Training.",
          image: "/img/corporate/mask2.png",
          link: "/corporate/training",
        },
        {
          title: "Talent as a Service",
          desc: "Program kemitraan dalam pemenuhan kebutuhan tenaga kerja atau talenta digital perusahaan mitra sebagai langkah efisien dari proses rekrutmen kandidat dan manajemen tenaga kerja yang selaras dengan kebutuhan dan tujuan bisnis yang diinginkan.",
          image: "/img/corporate/mask3.png",
          link: "/corporate/talent-service", // 
        },
        {
          title: "Technology Service",
          desc: "Akselerasi pengembangan produk digital perusahaan mitra melalui pemenuhan dan manajemen sumber daya manusia atau talenta digital, implementasi proses pengembangan produk, layanan IT Consulting hingga IT Support sesuai kebutuhan dan tujuan bisnis yang diinginkan.",
          image: "/img/corporate/mask4.png",
          link: "/corporate/services", // 
        },
      ],
      listAcceleration: [],
      usps: [
        {
          img: "/img/corporate/materi_relevan.png",
          name: "Akselerasi Pengembangan Perusahaan ",
          description:
            "Berikan peluang untuk strategi yang tepat dan inovasi terbaru dalam pengembangan profesional yang membantu perjalanan bisnis Anda.",
        },
        {
          img: "/img/corporate/keterlibatan_langsung.png",
          name: "100% Menyesuaikan Kebutuhan Klien",
          description:
            "Pelayanan yang disesuaikan dengan berbagai ukuran tim dan skala bisnis sesuai dengan kebutuhan dan perkembangan seiring waktu. ",
        },
        {
          img: "/img/corporate/intensive_program.png",
          name: "Reputable dan Terpercaya",
          description:
            "Pengalaman menghadirkan solusi bagi klien yang membuat kami memiliki sertifikasi yang menjamin kualitas mutu layanan berbagai kebutuhan bisnis.",
        },
        {
          img: "/img/corporate/profesional_talent.png",
          name: "Tim Profesional",
          description:
            "Tim profesional dan berdedikasi yang siap memberikan solusi prioritas terhadap kebutuhan spesifik untuk mencapai tujuan efektif bisnis Anda.",
        },
        {
          img: "/img/corporate/portfolio.png",
          name: "Performa Laporan Kinerja Akuntabel",
          description:
            "Laporan kinerja kami memberikan gambaran lengkap tentang performa perusahaan mitra secara transparan dan terperinci.",
        },
        {
          img: "/img/corporate/cs_responsif.png",
          name: "Pelayanan 24/7",
          description:
            "Tim support & manajemen layanan bantuan tersedia selama 24 jam dalam 7 hari untuk melayani setiap kebutuhan perusahaan.",
        },
      ],
      is_confirm: false,
      form: {
        full_name: "",
        email: "",
        phone_number: "",
        gender: "",
        corporate_name: "",
        company_field: "",
        program: "",
        total_student: 0,
        notes: "",
        category: "",
        web_url: "",
        job_pic: "",
      },
      categories: [
        {
          name: "Corporate Collaboration",
          value: "COLLABORATION",
        },
        {
          name: "Corporate Training",
          value: "TRAINING",
        },
        {
          name: "Talent as a Service",
          value: "TALENT_SERVICE",
        },
        {
          name: "Tech Service",
          value: "TECH_SERVICE",
        },
      ],
      uvps: Array(5).fill(null),
      questions: [],
      checkbox: false,
      isError: false,
      scrollStep: 0,
      scrollPercentage: 0,
      answerVisible: [],
      testimonies: [],
      alertErrMessage: "",
      isAlertErr: false,
    };
  },
  methods: {
    duptlicatePartners() {
      this.partners = this.partners.concat(
        this.partners,
        this.partners,
        this.partners
      );
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get(
          "/v1/testimony?category=CORPORATE&page=1&per_page=9"
        );
        this.testimonies = resp.data.data.testimonies;
        while (this.testimonies.length % 3 !== 0) {
          this.testimonies.push("");
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get("/v1/logo?category=CORPORATE");
        this.partners = resp.data.data.logos;
        this.duptlicatePartners();
      } catch (err) {
        console.log(err);
      }
    },
    toMethode(value) {
      if (!value) return;
      if (value.toLowerCase() === "online") {
        return "Daring";
      } else if (value.toLowerCase() === "offline") {
        return "Luring";
      } else {
        return value;
      }
    },
    toDiscount(item) {
      const total_price = item.price - item.discount;
      if (total_price <= 0) {
        return "FREE";
      } else {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return formatter.format(total_price);
      }
    },
    minimize(text) {
      return text.length > 290 ? text.slice(0, 290) + "..." : text;
    },
    async getMainClass() {
      try {
        const resp = await this.$http.get("/v1/class/category/6?is_main=true");
        this.listAcceleration = resp.data.data.classes;
      } catch (err) {
        console.log(err);
      }
    },
    scrollToClass() {
      window.scrollTo(0, 1000);
    },

    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya", "_blank");
    },
    toggleAnswer(index) {
      this.$set(this.answerVisible, index, !this.answerVisible[index]);
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active;
      this.questions.forEach((e) => {
        e.active = false;
      });
      this.questions[idx].active = !valDef;
    },
    async onSubmit() {
      if (!this.is_confirm) {
        return;
      }
      if (
        !this.form.corporate_name ||
        !this.form.full_name ||
        !this.form.job_pic ||
        !this.form.email ||
        !this.form.phone_number ||
        !this.form.category ||
        !this.form.web_url
      ) {
        this.alertErrMessage = "Harap isi semua form yang tersedia";
        this.isAlertErr = true;
        this.isError = true;
        return;
      }
      try {
        await this.$http.post("/v1/corporate-registration", this.form);
        this.alertErrMessage = "Success";
        this.isAlertErr = true;
        this.isError = false;
        this.clearForm();
      } catch {
        this.alertErrMessage = "Error saat submit data";
        this.isAlertErr = true;
        this.isError = true;
      }
    },
    clearForm() {
      this.is_confirm = false;
      this.form.full_name = "";
      this.form.email = "";
      this.form.phone_number = "";
      this.form.corporate_name = "";
      this.form.category = "";
      this.form.web_url = "";
      this.form.job_pic = "";
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=CORPORATE&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  mounted() {
    if (sessionStorage.getItem("nav-item-active-corp"))
      sessionStorage.removeItem("nav-item-active-corp");
    this.getClientLogo();
    this.getTestimoni();
    this.getFaq();
    this.getMainClass();
  },
};
</script>

<style src="./corporate.css" scoped></style>