<template>
  <v-app-bar fixed color="#162CA2" class="custom-header">
    <v-container d-flex>
      <v-toolbar flat style="background-color: #162CA2 !important;">
        <v-toolbar-title class="text-uppercase blue--text" style="cursor: pointer" @click="goToHomePage">
          <LogoNav  />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mr-5">
          <!-- <v-tabs color="#000080">
                        <v-tab v-for="link in links" :key="link.text" router :to="link.route" color="#000080">{{ link.text }}</v-tab>
                    </v-tabs> -->
          <div class="hc-nav-container">
            <div :class="`hc-nav-content ${!link.router ? 'sub-menu-hov' : ''}`" v-for="link in links" :key="link.text" @click="linkedNav(link.route, link.text)">
              <span :class="link.active ? 'active' : 'nonactive'">{{ link.text }} </span><Arrow v-if="!link.route" :down="true" />
              <div class="sub-menu" ref="menu_program" v-if="!link.route">
                <div class="sub-menu-child">
                  <div class="sub-menu-box" v-for="(item2, idx2) in link.child" :key="idx2">
                    <div class="sub-menu-content" @click="navigate(item2.router)">
                      <div style="width: 70px;">
                          <component :is="item2.img" alt="" style="width: 70px;"></component>
                      </div>
                      <div style="width: 100%; display:flex; flex-direction: column; justify-content: center">
                        <p class="sub-title">{{ item2.name }}</p>
                        <p style="font-size: 15px; margin: 0; font-weight: normal" v-if="item2.desc">{{ item2.desc }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-btn depressed color="#FFDE59" :class="{ display: is_login_show === false }" @click="goToLoginPage"
              class="btn-login-rounded" style="margin-left: 30px; width:120px; height: 45px">
              <span class="text-capitalize  font-weight-bold">MASUK</span>
            </v-btn>
          </div>
        </div>
      </v-toolbar>
    </v-container>
  </v-app-bar>
</template>

<script>
import Arrow from "@/assets/icons/Arrow2.vue";
import LogoNav from "@/assets/icons/LogoNav.vue";
import BootcampIc from './home/icons/BootcampIc.vue';
import PublicIc from './home/icons/Public.vue';
import CorpIc from './home/icons/CorpIc.vue';
import PraIc from './home/icons/PraIc.vue';
import EventIc from './home/icons/Event.vue';
import BeritaIc from './home/icons/BeritaIc.vue';
import FaqIc from './home/icons/FaqIc.vue';
import CeritaAlumniIc from './home/icons/CeritaAlumni.vue';

export default {
  data() {
    return {
      is_login_show: true,
      is_profile_show: true,
      isSubMenuActive: false,
      full_name: "",
      token: "",
      links: [
        {
          text: "Program", route: "", active: false, type: 1, child: [
            {
              img: BootcampIc,
              router: "/class/bootcamp",
              name: "Bootcamp",
              desc: "4 program akselerasi unggulan"
            },
            {
              img: PraIc,
              router: "/prakerja",
              name: "Kartu Prakerja",
              desc: "7 program unggulan & 2.000+ alumni"
            },
            {
              img: PublicIc,
              router: "/class/public-training",
              name: "Public Training",
              desc: "30+ program unggulan"
            },
            {
              img: EventIc,
              router: "/event",
              name: "Event",
              desc: "3 program unggulan"
            },
            {
              img: CorpIc,
              router: "/corporate",
              name: "Corporate Service",
              desc: "30+ mitra"
            },
          ]
        },
        {
          text: "Literasi",
          route: "",
          active: false,
          type: null,
          child: [
            {
              img: BeritaIc,
              router: "/blog",
              name: "Artikel",
              desc: "",
            },
            {
              img: FaqIc,
              router: "/faq",
              name: "FAQ",
              desc: "",
            },
            {
              img: CeritaAlumniIc,
              router: "/alumni-stories",
              name: "Cerita Alumni",
              desc: "",
            },
          ]
        },
        { text: "Karir", route: "/career", active: false, type: null, child: [] },
        { text: "Tentang Kami", route: "/about", active: false, type: null, child: [] },
      ],
    };
  },
  watch: {
    $route() {
      this.activeNav();
    },
  },
  components: {
    Arrow,
    LogoNav,
    BootcampIc,
    PraIc,
    PublicIc,
    CorpIc,
    EventIc,
    BeritaIc,
    // CeritaAlumniIc,
    FaqIc
  },
  methods: {
    // logout(){
    //     // localStorage.removeItem("user");
    //     localStorage.clear();
    //     this.is_profile_show = false
    //     this.is_login_show = true
    //     window.location.href = '/';
    // },
    // goToDashboard() {
    //     window.open(
    //         'https://dashboard.hicolleagues.com/home/'+this.token+"/"+this.full_name,
    //         '_blank' // <- This is what makes it open in a new window.
    //     )
    // },
    activeNav() {
      this.links.forEach((item) => (item.active = false));
      if (this.$route.path === "/") {
        return
      } else  if (this.$route.path === "/class/bootcamp" ||this.$route.path == "/class/public-training" || this.$route.path == "/prakerja" ||this.$route.path == "/event") {
        this.links[0].active = true;
        sessionStorage.setItem("nav-item-active", 0);
        return;
      } else if (this.$route.path === "/faq" || this.$route.path === "/alumni-stories" ) {
        this.links[1].active = true;
        sessionStorage.setItem("nav-item-active", 1);
        return;
      }
      try {
        let beforActiveIdx;
        const validPath = this.links.find((item) => item.route === this.$route.path);
        if (validPath) {
          this.links.find((item) => item.route === this.$route.path).active = true;
          let beforActiveIdx = this.links.findIndex((item) => item.active == true);
          this.isSubMenuActive = false;
          if (beforActiveIdx < 0) return;
          sessionStorage.setItem("nav-item-active", beforActiveIdx);
        } else {
          if (!beforActiveIdx) {
            beforActiveIdx = sessionStorage.getItem("nav-item-active");
          }
          this.links[beforActiveIdx].active = true;
        }
      } catch {
        undefined;
      }
    },
    linkedNav(link, cy) {
      if (cy === "Program") {
        this.links.forEach((item) => (item.active = false));
        this.links[0].active = true;
        this.isSubMenuActive = !this.isSubMenuActive;
        return;
      } else if (cy === "Literasi") {
        this.links.forEach((item) => (item.active = false));
        this.links[1].active = true;
        this.isSubMenuActive = !this.isSubMenuActive;
        return;
      }
      this.$router.push(link);
    },
    goToHomePage() {
      window.location.href = "/";
    },
    goToLoginPage() {
      window.location.href = "/login";
    },
    navigate(link) {
      const childSpanClassAttr = this.$refs.menu_program[0];
      childSpanClassAttr.style = "display: none";
      this.isSubMenuActive = false;
      this.$router.push(link);
      setTimeout(() => {
        childSpanClassAttr.style = "";
      }, 500);
    },
  },
  mounted() {
    this.activeNav();
  },
};
</script>

<style src="./css/header.css" scoped></style>
