<template>
    <div>
        <Loading v-if="isLoading" />
        <popup
      :message="errMessage"
      v-if="isErr"
      @close="isErr = false"
    />
        <div class="header">
            <div class="header-info">
                <div class="header-left">
                    <div class="career">
                        <div class="career-top">
                            <span class="yellow-text font-poppins" @click="$router.push('/career')">Karir</span>
                            <div>
                                <Arrow :width="20" :height="20" direct="left" strokeColor="white" class="icon" />
                            </div>
                            <span class="yellow-text font-poppins"
                                @click="$router.push(`/career/${careerDetail.id}`)">Karir Detail</span>
                            <div>
                                <Arrow :width="20" :height="20" direct="left" strokeColor="white" class="icon" />
                            </div>
                            <span class="font-poppins">{{ careerDetail.name }}</span>
                        </div>
                        <div class="career-info">
                            <div class="career-title">
                                <h1>{{ careerDetail.name }}</h1>
                            </div>
                            <div class="career-info-ctn">
                                <span class="font-poppins">{{ careerDetail.company }}</span>
                                <Bullet style="margin-left: 7px; margin-right: 7px" />
                                <span class="font-poppins">{{ careerDetail.location }}</span>
                            </div>
                        </div>
                        <div class="career-data">
                            <div class="label">
                                <div style="background-color: #f1f6ff" v-if="careerDetail.contract_type">
                                    <clock-ic />
                                    <p>{{ careerDetail.contract_type }}</p>
                                </div>
                                <div style="background-color: #faeceb" v-if="careerDetail.work_type">
                                    <location-ic />
                                    <p>{{ careerDetail.work_type }}</p>
                                </div>
                                <div style="background-color: #faf6e7" v-if="careerDetail.level">
                                    <level-ic />
                                    <p>{{ careerDetail.level }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="career-info" style="margin-top: 20px;">
                            <div class="career-info-ctn">
                                <span class="font-poppins">Ditutup pada <b class="font-poppins">{{
                                careerDetail.closed_at }}</b></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div style="background-color: rgba(245, 246, 250, 1);">
            <div class="career-detail">
                <div class="career-detail-item">
                    <h2 class="mb-5">Personal Data</h2>
                    <div class="form-ctn">
                        <div class="form-ctn-left">
                            <label class="form-label">Nama Lengkap
                                <span style="color: #c63c38">*</span></label>
                        </div>
                        <div class="form-ctn-right">
                            <v-text-field class="form-input text-form" v-model="form.full_name" outlined
                                placeholder="Masukkan Nama Lengkap Anda" hide-details />
                        </div>
                    </div>
                    <div class="form-ctn">
                        <div class="form-ctn-left">
                            <label class="form-label">Email
                                <span style="color: #c63c38">*</span></label>
                        </div>
                        <div class="form-ctn-right">
                            <v-text-field class="form-input text-form" v-model="form.email" outlined
                                placeholder="Masukkan Email Anda" hide-details />
                        </div>
                    </div>
                    <div class="form-ctn">
                        <div class="form-ctn-left">
                            <label class="form-label">No. Whatsapp
                                <span style="color: #c63c38">*</span></label>
                        </div>
                        <div class="form-ctn-right">
                            <v-text-field class="form-input text-form" type="number" v-model="form.phone_number" outlined
                                placeholder="Misal: 0813-xxxxx-xxxx" hide-details />
                        </div>
                    </div>
                    <div class="form-ctn">
                        <div class="form-ctn-left">
                            <label class="form-label">Domisili</label>
                        </div>
                        <div class="form-ctn-right">
                            <v-text-field class="form-input text-form" v-model="form.location" outlined
                                placeholder="Masukkan Kota Domilisi" hide-details />
                        </div>
                    </div>
                    <div class="form-ctn">
                        <div class="form-ctn-left">
                            <label class="form-label">Perusahaan Saat Ini</label>
                        </div>
                        <div class="form-ctn-right">
                            <v-text-field class="form-input text-form" v-model="form.current_company" outlined
                                placeholder="Masukkan Nama Perusahaan Saat Ini" hide-details />
                        </div>
                    </div>
                    <div class="form-ctn">
                        <div class="form-ctn-left">
                            <label class="form-label">Jabatan Saat Ini</label>
                        </div>
                        <div class="form-ctn-right">
                            <v-text-field class="form-input text-form" v-model="form.current_level" outlined
                                placeholder="Masukkan Jabatan Saat Ini" hide-details />
                        </div>
                    </div>
                </div>
                <div class="career-detail-item" style="margin-top: 80px;">
                    <h2 class="mt-5">Lampiran</h2>
                    <div class="form-ctn">
                        <div class="form-ctn-left">
                            <label class="form-label">Linkedin (Profil)
                                <span style="color: #c63c38">*</span></label>
                        </div>
                        <div class="form-ctn-right">
                            <v-text-field class="form-input text-form" v-model="form.linkedin_url" outlined
                                placeholder="Masukkan URL Linkedin" hide-details />
                        </div>
                    </div>
                    <div class="form-ctn">
                        <div class="form-ctn-left">
                            <label class="form-label">Resume/CV
                                <span style="color: #c63c38">*</span></label>
                        </div>
                        <div class="form-ctn-right">
                            <v-file-input v-model="resume_cv" accept="pdf/*" placeholder="Unggah Resume/CV"
                                class="form-upload" outlined hide-details>
                                <template v-slot:prepend-inner v-if="resume_cv">
                                    <ImageModal :src="form.resume_url" :width="76.31" :height="37" :radius="3" />
                                </template>
                            </v-file-input>
                            <p class="note-file">
                                *Ukuran file maks 2 MB
                            </p>
                        </div>
                    </div>
                    <div class="form-ctn">
                        <div class="form-ctn-left">
                            <label class="form-label">Portfolio</label>
                        </div>
                        <div class="form-ctn-right">
                            <v-file-input v-model="portfolio_cv" accept="pdf/*" placeholder="Unggah Portfolio"
                                class="form-upload"  outlined hide-details>
                                <template v-slot:prepend-inner v-if="portfolio_cv">
                                    <ImageModal :src="form.portfolio_url" :width="76.31" :height="37" :radius="3" />
                                </template>
                            </v-file-input>
                            <p class="note-file">
                                *Ukuran file maks 2 MB
                            </p>
                        </div>
                    </div>
                    <div class="form-ctn">
                        <div class="form-ctn-left">
                            <label class="form-label">Website Lainnya</label>
                        </div>
                        <div class="form-ctn-right">
                            <v-text-field class="form-input text-form" v-model="form.website" outlined
                                placeholder="Masukkan URL Website" hide-details />
                        </div>
                    </div>
                </div>
                <div style="margin-top: 90px;">
                    <h3>Informasi Lain</h3>
                    <v-textarea outlined v-model="form.notes" class="mt-2 rounded-sm" hide-details=""
                        placeholder="Tulis catatan tambahan di sini..." rows="4"></v-textarea>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 90px">
                    <Button name="KIRIM LAMARAN" :disabled="!validData" width="350px" height="55px" @click="() => toCheckout()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/others/Button.vue";
import ImageModal from "@/components/ImageModal.vue";

import Arrow from "@/assets/icons/Arrow.vue";
import Bullet from "@/assets/icons/Bullet.vue";
import ClockIc from '@/assets/icons/career/Clock.vue';
import LevelIc from '@/assets/icons/career/Level.vue';
import LocationIc from '@/assets/icons/career/Location.vue';
import Popup from "@/components/others/Popup.vue";
import Loading from '../../../ilustration/Loading.vue';

export default {
    name: "DesktopCareerApplicant",
    components: {
        Button,
        ClockIc,
        LevelIc,
        LocationIc,
        Arrow,
        Bullet,
        ImageModal,
        Popup,
        Loading
    },
    data() {
        return {
            careerDetail: null,
            show_alert: false,
            resume_cv: null,
            portfolio_cv: null,
            isLoading: false,
            errMessage: "",
            isErr: false,
            form: {
                career_id: '',
                full_name: "",
                email: "",
                phone_number: "",
                linkedin_url: "",
                resume_url: "",
                portfolio_url: "",
                location: "",
                current_company: "",
                current_level: "",
                website: "",
                notes: "",
            }
        };
    },
    computed: {
        validData() {
        return (
            this.form.full_name &&
            this.form.email &&
            this.form.phone_number &&
            this.form.linkedin_url
        );
        },
    },
    watch: {
        show_alert() {
            if (!this.show_alert) return;
            setTimeout(() => {
                this.show_alert = false;
            }, 3000);
        },
    },
    methods: {
        async getCareerDetail() {
            await this.$http
                .get(`/v1/career/${this.$route.params.id}`)
                .then((response) => {
                    this.careerDetail = response.data.data;
                    const options = { year: "numeric", month: "long", day: "numeric" };
                    const formatter = new Intl.DateTimeFormat("id-ID", options);

                    const startDate = new Date(this.careerDetail.started_at);
                    this.careerDetail.started_at = formatter.format(startDate);

                    const closedAt = new Date(this.careerDetail.closed_at);
                    this.careerDetail.closed_at = formatter.format(closedAt);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    this.$router.push("/not-found");
                });
        },
        onCopyUrl() {
            const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;
            clipboardData.writeText(window.location.href);
            this.show_alert = true;
        },
        async toCheckout() {
            this.isLoading = true;
            try {
                this.form.career_id = this.careerDetail.id
                const imageForm = new FormData();
                imageForm.append("attachment", this.portfolio_cv);
                const webRes = await this.$crm_http.post("/v1/upload-file",imageForm);
                this.form.portfolio_url = webRes.data.data.upload_image_response.url_w_query;

                imageForm.delete("attachment");
                imageForm.append("attachment", this.resume_cv);
                const mobileRes = await this.$crm_http.post("/v1/upload-file",imageForm);
                this.form.resume_url = mobileRes.data.data.upload_image_response.url_w_query;

                await this.$http.post("/v1/career-application", this.form);
                imageForm.delete("attachment");
                setTimeout(() => {
                    this.$router.push("/career-success");
                }, 2000);
            } catch (err) {
                this.isLoading = false;
                this.isErr = true
                if (err.response) {
                    this.errMessage = err.response.data.data
                } else {
                    this.errMessage = "File Size to large"
                }
            }
        }
    },
    mounted() {
        // Make a GET request to the API endpoint
        this.getCareerDetail();
    },
};
</script>

<style src="./applicant.css" scoped></style>