import { render, staticRenderFns } from "./Applicant.vue?vue&type=template&id=5b8420b7&scoped=true&"
import script from "./Applicant.vue?vue&type=script&lang=js&"
export * from "./Applicant.vue?vue&type=script&lang=js&"
import style0 from "./applicant.css?vue&type=style&index=0&id=5b8420b7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8420b7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VFileInput,VTextField,VTextarea})
