<template>
  <div style="padding-top: 50px">
    <img :src="classDetail.image_url" class="img-banner" />
    <div class="detail-body">
      <div class="container">
        <div class="d-flex" style="justify-content: center; margin: 20px 0">
          <div class="btn-white-oval font-poppins">Bootcamp</div>
          <div class="btn-white-oval font-poppins">{{ classDetail.method ? classDetail.method : 'Oflline/Online' }}</div>
        </div>
        <div class="text-center">
          <h3 class="sub-title-span">
            {{ classDetail.name }}
          </h3>
          <p style="font-size: 13px" v-if="classDetail.schedules.length">
            {{ formatDate(classDetail.schedules[0].start_date) }}
          </p>
          <p>Tingkatkan skill menjadi profesional dengan Job Guarantee Program dan dapatkan unlimited career support sampai kamu diterima bekerja</p>
          <v-btn depressed color="#FFDE59" @click="goToCheckoutPage" class="button-daftar">
            <span class="text-capitalize font-weight-bold blue-text" @click="goToCheckoutPage">DAFTAR SEKARANG</span>
          </v-btn>
          <v-btn depressed color="#FFFFFF" @click="sendToWa" class="button-daftar" style="border: 1px solid">
            <span class="text-capitalize font-weight-bold blue-text">KONSULTASI GRATIS</span>
          </v-btn>
        </div>
        <div class="mt-10">
          <span style="font-size: 14px">Info Kelas</span>
          <h3>Deskripsi Program</h3>
          <p class="desc-program mt-5">{{ classDetail.description }}</p>
        </div>
        <h5 class="title" style="margin-top: 40px; margin-bottom: 20px; font-family: 'Poppins', sans-serif !important">Mekanisme Kelas</h5>
        <div>
          <div class="stroke-yellow-box d-flex">
            <div class="book">
              <Grow />
            </div>
            <div style="padding-bottom: 10px">
              <div class="title-mechanism"><span>Level</span></div>
              <div class="sub-title-mechanism">
                <span>{{ classDetail.level }}</span>
              </div>
            </div>
          </div>

          <div class="stroke-yellow-box d-flex">
            <div class="book">
              <Methode />
            </div>
            <div style="padding-bottom: 10px">
              <div class="title-mechanism"><span>Metode</span></div>
              <div class="sub-title-mechanism">
                <span>{{ classDetail.method ? classDetail.method : 'Oflline/Online' }}</span>
              </div>
            </div>
          </div>

          <div class="stroke-yellow-box d-flex">
            <div class="book">
              <DateIcon />
            </div>
            <div style="padding-bottom: 10px">
              <div class="title-mechanism"><span>Sesi Pertemuan</span></div>
              <div class="sub-title-mechanism">
                <span>{{ classDetail.session_total }} sesi</span>
              </div>
            </div>
          </div>

          <div class="stroke-yellow-box d-flex">
            <div class="book">
              <Clock />
            </div>
            <div style="padding-bottom: 10px">
              <div class="title-mechanism">
                <span>Hari & Jam Pertemuan</span>
              </div>
              <div class="sub-title-mechanism">
                <span>{{ classDetail.days }}<br />{{ classDetail.hours }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-container">
        <div style="margin-top: 45px; padding: 40px 0px">
          <span>Materi Silabus</span>
          <h5 class="title mb-4" style="font-family: 'Poppins', sans-serif !important; line-height: 25px">Materi yang Akan HiColers Pelajari</h5>
          <!-- <p class="mb-4">
            Lorem ipsum dolor sit amet consectetur. Id nullam lorem interdum
            proin nisi. Amet ut ullamcorper eu vestibulum et cras lobortis.
            Convallis purus ut ultricies tempor euismod eget. Massa sed quis
            orci nunc tellus dolor id metus.
          </p> -->
          <div v-for="(item, idx) in classDetail.summary_syllabus" :key="idx" class="faq-box" @click="item.show = !item.show">
            <div class="fax-box-info">
              <p style="width: 90%">{{ item.name }}</p>
            </div>
            <p v-if="item.show" class="faq-box-active" v-html="item.value"></p>
          </div>
        </div>
      </div>
      <div class="container-parent-grdn">
        <span>Benefit</span>
        <h5 class="title" style="font-family: 'Poppins', sans-serif !important">Apa Saja yang Peserta Dapatkan</h5>
        <div class="benefits" v-for="(item, idx) in classDetail.benefits" :key="idx">
          <CheckIc :width="26" :height="26" outline fill="#575966" />
          <span>{{ item }}</span>
        </div>
        <div class="mt-10" ref="tujuan_pelatihan">
          <span>Tujuan Pembelajaran</span>
          <h5 class="title" style="font-family: 'Poppins', sans-serif !important">Goal pelatihan yang didapatkan</h5>
          <p style="margin-top: 30px; margin-bottom: 40px" ref="learning_objective_ctn" v-html="classDetail.learning_objective"></p>
        </div>
        <div>
          <span>Target Peserta</span>
          <h5 class="title" style="font-family: 'Poppins', sans-serif !important">Kelas ini Cocok untuk</h5>
          <ul>
            <li v-for="(item, idx) in classDetail.target_audience" :key="idx">
              {{ item.level }}
            </li>
          </ul>
        </div>
      </div>
      <div class="container">
        <h5 class="title mt-5" style="font-family: 'Poppins', sans-serif !important; line-height: 25px">Kenapa Pilih Bootcamp HiColleagues?</h5>
        <div class="card-hicol" v-for="(item, idx) in whyHiColl" :key="idx">
          <div>
            <img :src="item.img" width="70" alt="Ctn" />
          </div>
          <div>
            <h3>{{ item.title }}</h3>
            <p>{{ item.desc }}</p>
          </div>
        </div>
        <span>Metode Belajar</span>
        <h5 class="title" style="line-height: 25px; margin-bottom: 20px; font-family: 'Poppins', sans-serif !important">Ikuti Bootcamp HiColleagues dengan Metode Belajar yang Konstruktif</h5>
        <div v-for="(item, idx) in methodLearning" :key="idx" style="display: flex; gap: 10px; margin-top: 10px">
          <div class="circle-yellow">
            <img :src="item.img" width="28" alt="Ctn" />
          </div>
          <div style="width: 80%">
            <h3>{{ item.title }}</h3>
            <p>{{ item.desc }}</p>
          </div>
        </div>
        <br />
        <span>Biaya Pelatihan</span>
        <h5 class="title" style="line-height: 25px; margin-bottom: 20px; font-family: 'Poppins', sans-serif !important">Investasi Masa Depan yang Terjangkau</h5>
        <div class="box-price">
          <div class="early-bird">
            <span class="eb">Harga Reguler</span>
            <h1>{{ classDetail.price | toCurrency }}</h1>
            <div class="line-height"></div>
            <span v-if="classDetail.is_discount" class="eb text-center" style="margin: 0">Promo Harga Early Bird</span>
            <h1 v-if="classDetail.is_discount">
              {{ classDetail.discount_price | toCurrency }}
            </h1>
            <span style="margin-top: 10px" v-if="classDetail.is_discount">(Sampai {{ formatDate(classDetail.end_discount_date) }})</span>
            <div class="event-due-date" v-if="classDetail.is_discount && expired.length">
              <div class="event-due-date-ctn">
                <span>{{ expired[0] }}</span>
                <span>Hari</span>
              </div>
              <span>:</span>
              <div class="event-due-date-ctn">
                <span>{{ expired[1] }}</span>
                <span>Jam</span>
              </div>
              <span>:</span>
              <div class="event-due-date-ctn">
                <span>{{ expired[2] }}</span>
                <span>Menit</span>
              </div>
              <span>:</span>
              <div class="event-due-date-ctn">
                <span>{{ expired[3] }}</span>
                <span>Detik</span>
              </div>
            </div>
          </div>
          <div class="info-price-add" v-for="(item, idx) in priceAddInfo" :key="idx">
            <check-blue />
            <span style="width: 90%">{{ item }}</span>
          </div>
          <div style="margin: 20px 0">
            <button-yellow name="DAFTAR SEKARANG" width="100%" @click="goToCheckoutPage" />
          </div>
        </div>
      </div>
      <div class="bg-container" v-if="!classDetail.is_discount">
        <div class="container-detail" style="margin-top: 45px; padding: 40px 0px">
          <h5 class="title" style="line-height: 25px; font-family: 'Poppins', sans-serif !important">Program Cicilan untuk Investasi Masa Depan</h5>
          <div class="cicilan" v-for="(item, idx) in methodePembayaran" :key="idx">
            <div class="cicilan-head">
              <p>{{ item.title }}</p>
              <span style="font-size: 14px">{{ item.type }}</span>
            </div>
            <div class="cicilan-body">
              <ul>
                <li class="cicilan-body-ctn" v-for="(item2, idx2) in item.list" :key="idx2" v-html="item2"></li>
              </ul>
            </div>
          </div>
          <div style="margin-top: 40px">
            <button-yellow name="KONSULTASI PROGRAM CICILAN" width="100%" @click="goToCustomerService" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import Grow from '@/assets/icons/Grow.vue';
import Methode from '@/assets/icons/Methode.vue';
import DateIcon from '@/assets/icons/Date.vue';
import Clock from '@/assets/icons/Clock.vue';
import CheckBlue from '@/assets/icons/Check.vue';
import CheckIc from '@/assets/icons/Check.vue';

// import Button from "../../others/Button.vue";
import ButtonYellow from '../../others/ButtonYellow.vue';

export default {
  name: 'DetailClassBootcampMobile',
  components: {
    // Button,
    CheckIc,
    ButtonYellow,
    CheckBlue,
    Grow,
    Methode,
    DateIcon,
    Clock,
  },
  data() {
    return {
      whyHiColl: [
        {
          img: '/img/bootcamp/ic_intensive_program.png',
          title: 'Intensive Program',
          desc: 'Durasi program 2-3 bulan yang mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri',
        },
        {
          img: '/img/bootcamp/ic_competent_trainer.png',
          title: 'Competent Trainer',
          desc: 'Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan',
        },
        {
          img: '/img/bootcamp/ic_sertifikat_completion.png',
          title: 'Learning Management System',
          desc: 'Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat',
        },
        {
          img: '/img/bootcamp/ic_sertifikat_kompetensi.png',
          title: 'E-Certificate',
          desc: 'Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir',
        },
        {
          img: '/img/bootcamp/ic_job_recommentation.png',
          title: 'Job Connector',
          desc: 'Update informasi dan perekomendasian peluang kerja di industri nyata bagi alumni peserta Bootcamp',
        },
        {
          img: '/img/bootcamp/ic_installment.png',
          title: 'Installment Option Available',
          desc: 'Biaya pelatihan yang terjangkau hingga program pembayaran dengan sistem cicilan untuk mendukung akselerasi skill peserta',
        },
      ],
      methodLearning: [
        {
          img: '/img/bootcamp/chat.png',
          title: 'Join Community Group',
          desc: 'Tersedia grup Telegram untuk memfasilitasi kolaborasi, konsultasi, dan pertukaran informasi antara peserta, trainer, dan admin grup.',
        },
        {
          img: '/img/bootcamp/zoom.png',
          title: 'Live Class via Zoom',
          desc: 'Peserta akan mengikuti sesi pembelajaran secara daring menggunakan Zoom.',
        },
        {
          img: '/img/bootcamp/lms.png',
          title: 'Access to LMS',
          desc: 'Peserta mendapatkan akses LMS untuk mempermudah proses pembelajaran selama bootcamp.',
        },
        {
          img: '/img/bootcamp/partical.png',
          title: 'Practical Learning',
          desc: 'Peserta belajar melalui materi dan langsung menerapkannya dalam pengerjaan proyek yang relevan.',
        },
        {
          img: '/img/bootcamp/sharing.png',
          title: 'Sharing Session',
          desc: 'Peserta dapat berdiskusi dan mendengarkan pengalaman langsung dari trainer ketika sesi pembelajaran berlangsung.',
        },
        {
          img: '/img/bootcamp/coumunication.png',
          title: 'Live Consultation',
          desc: 'Peserta berkesempatan mendapatkan bimbingan dan mentoring pribadi langsung dari trainer.',
        },
        {
          img: '/img/bootcamp/project.png',
          title: 'Final Project & Exam',
          desc: 'Pada akhir pembelajaran, peserta wajib untuk mengerjakan proyek akhir dan ujian akhir untuk mengukur pemahaman peserta.',
        },
      ],
      menuPublicClass: [
        { name: 'Info Kelas', id: 1, position: 0, layout: 'info-kelas' },
        {
          name: 'Timeline Belajar',
          id: 2,
          position: 0,
          layout: 'timeline-belajar',
        },
        { name: 'Materi Silabus', id: 3, position: 0, layout: 'materi-silabus' },
        { name: 'Benefit', id: 4, position: 0, layout: 'benefit' },
        {
          name: 'Tujuan Pelatihan',
          id: 5,
          position: 0,
          layout: 'tujuan-pelatihan',
        },
        { name: 'Target Peserta', id: 6, position: 0, layout: 'tagret-peserta' },
        {
          name: 'Kenapa HiColleagues',
          id: 7,
          position: 0,
          layout: 'kenapa-hicoleagues',
        },
        { name: 'Metode Belajar', id: 8, position: 0, layout: 'methode-belajar' },
        {
          name: 'Biaya Pelatihan',
          id: 9,
          position: 0,
          layout: 'biaya-pelatihan',
        },
      ],
      methodePembayaran: [
        {
          title: 'Pembayaran Pertama',
          type: '(Saat Pendaftaran)',
          list: [
            '<span>Ketika calon peserta mendaftar untuk bootcamp, calon peserta akan diminta untuk</span> membayar pembayaran pertama.',
            '<span>Besaran</span> pembayaran pertama adalah 1/3 dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>',
            '<span>Calon peserta akan dianggap</span> resmi terdaftar setelah pembayaran pertama diterima',
          ],
        },
        {
          title: 'Pembayaran Kedua',
          type: '(Tengah Training / Senin, 13 November 2023)',
          list: [
            'Pembayaran kedua akan jatuh tempo pada tengah periode pelatihan, <span>yaitu pada tanggal yang telah ditentukan dalam perjanjian pendaftaran.</span>',
            '<span>Besaran</span> pembayaran kedua juga adalah 1/3 dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>',
            '<span>Peserta akan</span> diingatkan sebelum jatuh tempo <span>pembayaran kedua</span>',
          ],
        },
        {
          title: 'Pembayaran Ketiga',
          type: '(Sebelum Penerimaan Sertifikat)',
          list: [
            '<span>Pembayaran ketiga akan dilakukan setelah calon peserta</span> menyelesaikan seluruh pelatihan dan memenuhi semua persyaratan <span>yang diperlukan.</span>',
            'Pembayaran ketiga adalah sisa dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>',
            '<span>Peserta akan diingatkan</span> sebelum jatuh tempo <span>pembayaran ketiga.</span>',
            '<span>Setelah pembayaran ketiga diterima, peserta akan diberikan</span> sertifikat sebagai bukti kelulusan.',
          ],
        },
      ],
      priceAddInfo: ['Tipe kelas Public', 'Kapasitas max 10 peserta', 'Jadwal kelas telah ditentukan', 'Diajarkan langsung oleh mentor', 'Gratis Survival Kit', 'Sertifikat Kelulusan'],
      classDetail: {},
      expired: [],
      layout: 'info-kelas',
    };
  },
  methods: {
    sendToWa() {
      window.open('https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya');
    },
    goToCheckoutPage() {
      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.is_discount ? this.classDetail.discount_price : this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: 'BOOTCAMP_CLASS',
        methode: this.classDetail.method,
        days: this.classDetail.days,
        schedule: this.classDetail.schedules,
        category_id: this.classDetail.category_id,
      });
      localStorage.clear();
      localStorage.setItem('checkout', req);
      window.location.href = '/checkout';
    },
    goToCustomerService() {
      window.location.href = 'https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya';
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        const end = new Date(this.classDetail.end_discount_date);
        const now = new Date();
        const distance = end - now;
        if (distance < 0) {
          clearInterval(timer);
          this.expired = [];
          return;
        }
        days = Math.floor(distance / _day);
        hours = Math.floor((distance % _day) / _hour);
        minutes = Math.floor((distance % _hour) / _minute);
        seconds = Math.floor((distance % _minute) / _second);
        this.expired = [days, hours, minutes, seconds];
      };
      timer = setInterval(showRemaining, 1000);
    },
    // async getDetailClass() {
    //   // this.spin_load.val = true
    //   await this.$http
    //     .get('/v1/class-detail/' + this.$route.params.slug_name)
    //     .then((response) => {
    //       const data = response.data.data.class_detail;
    //       this.classDetail = {
    //         ...data,
    //         summary_syllabus: data.summary_syllabus
    //           ? data.summary_syllabus.map((item) => ({
    //               ...item,
    //               show: true,
    //             }))
    //           : [],
    //       };
    //       this.countDownTimer();
    //       setTimeout(() => {
    //         this.setToPoppinsListLearningObjective();
    //       }, 500);
    //     })
    //     .catch((err) => console.error(err));
    //   // .finally(() => this.spin_load.val = false)
    // },
    formatDate(date) {
      return moment(date).format('DD MMMM YYYY');
    },
    setToPoppinsListLearningObjective() {
      const elementLi = this.$refs.learning_objective_ctn.getElementsByTagName('li');
      for (let i = 0; i < elementLi.length; i++) {
        elementLi[i].style.fontFamily = 'Poppins';
      }
    },
  },
  mounted() {
    this.getDetailClass();
    window.scrollTo(0, 0);
  },
};
</script>

<style src="./css/detailClassBootcamp.css" scoped></style>