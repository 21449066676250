<template>
  <div style="margin-top: 63px">
    <v-alert class="alert-info" type="success" :value="show_alert">Copied</v-alert>
    <popup
      :message="errMessage"
      v-if="isErr"
      @close="isErr = false"
    />
    <div class="hero">
      <div class="content">
        <h1> Temukan Peluang Besar untuk Wujudkan Karir Impian</h1>
        <p>Sambut peluang masa depan dan tingkatkan karir cemerlang bersama HiColleagues</p>
        <div class="btn-cta">
          <button class="now" @click="scrollToSection()">INFO PEKERJAAN</button>
          <button class="call" @click="sendToWa">KONSULTASI GRATIS</button>
        </div>
      </div>
      <div class="card-img">
        <img src="/img/career/main.png" width="520" height="" alt="" style="border-radius: 0 0 0 80px" />
      </div>
    </div>
    <div class="unique-selling-proposition">
      <h1 class="text-center poppins-font usp-title">
        HiColleagues Pilihan Tepat dalam Wujudkan Karir Impian
        <div class="content">
          <div class="row">
            <div class="col-lg-4 items" v-for="(item, idx) in usps" :key="idx">
              <div class="card text-start" style="background-color: white">
                <img :src="item.img" alt="" width="60" height="60" />
                <div class="label">
                  <p class="sub-title">{{ item.name }}</p>
                  <p class="description">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </h1>
    </div>
    <div class="bg-gradient">
        <StepCollaboration title="Langkah Mudah Menjemput Karir Impian" :steps="steps" bgColor="white" />
    </div>
    <div class="unique-selling-proposition">
      <h1 class="text-center poppins-font usp-title">
        Jelajahi Peluang Karir HiColleagues
      </h1>
      <div class="content">
        <v-row gap="4">
          <v-col cols="5">
            <div class="action-head">
              <div class="f-search">
                <div class="f-search-ic">
                  <img src="/img/icons/search.png" alt="Search Ic" />
                </div>
                <div style="width: 90%">
                  <v-text-field v-model="filter.position" type="text" class="f-input" placeholder="Cari Pekerjaan" dense
                    flat solo></v-text-field>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols='2.5'>
            <v-select outlined color="#ACAFBF" placeholder="Tipe Pekerjaan" v-model="filter.work_type"
              :items="workTypeList" :item-text="'name'" :item-value="'value'" style="border-radius: 10px !important; background-color: white" hide-details />
          </v-col>
          <v-col cols='2.5'>
            <v-select outlined color="#ACAFBF" placeholder="Level Pekerjaan" v-model="filter.level" :items="levelList"
              :item-text="'name'" :item-value="'value'" style="border-radius: 10px !important; background-color: white" hide-details />
          </v-col>
          <v-col>
            <Button name="CARI" width="100%" height="54px" @click="() => getCareer()" />
          </v-col>
        </v-row>
        <div class="list-career">
          <div class="list-career-ctn" v-for="(data, idx) in listCareer" :key="idx" @click="$router.push(`/career/${data.id}`)">
            <img :src="data.logo ? data.logo : '/img/career/logo_sample.png'" />
            <h2 class="mb-3">{{ data.name }}</h2>
            <p style="font-weight: 500">{{ data.company }}</p>
            <p style="font-weight: 400; ">{{ data.location }}</p>
            <div class="career-data">
              <div class="label">
                <div style="background-color: #f1f6ff" v-if="data.contract_type">
                  <clock-ic width="18px" height="18px" />
                  <p>{{ data.contract_type }}</p>
                </div>
                <div style="background-color: #faeceb" v-if="data.work_type">
                  <location-ic width="18px" height="18px"  />
                  <p>{{ data.work_type }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="data.level">
                  <level-ic width="18px" height="18px"  />
                  <p>{{ data.level }}</p>
                </div>
              </div>
            </div>
            <div class="btn-daftar">
              <div class="btn-daftar-act">
                <Button name="LAMAR SEKARANG" width="100%" @click="() => toCheckout(data)" />
              </div>
              <div class="btn-daftar-cpy">
                <div class="button-outline" @click.stop="() => onCopy()"> 
                  <CopyIc width="25px" height="25px" />
                </div>
              </div>
            </div>
            <p class="text-center">Batas Lamar <b style="font-family: 'Poppins';">{{ moment(data.closed_at) }}</b></p>
          </div>
        </div>
        <EmptyData
          v-if="!listCareer || !listCareer.length"
          message="Belum ada Karir saat ini"
        />
        <div class="pagination mt-5" v-if="total > 1">
          <span class="page-link" @click="page('prev')">Sebelumnya</span>
          <span
            :class="`page-link ${currentPage === idx ? 'active' : ''}`"
            v-for="idx in total"
            :key="idx"
            >{{ idx }}</span
          >
          <span class="page-link" @click="page('next')">Selanjutnya</span>
        </div>
      </div>
    </div>
    <!-- <div class="list-of-acceleration" style="background-color: white;">
      <div class="container">
        <div style="display: flex; gap: 30px; ">
          <div style="max-width: 40%;">
            <p class="margin-0">Testimoni</p>
            <h1 class="margin-0" style="line-height: 35px;">Cerita Alumni Menuju Talenta Digital Kompeten</h1>
            <div class="btn-stoke-blue" style="margin: 20px 0 0 0; width: 300px;" @click="$router.push('/testimony')">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
              <ArrowLong />
            </div>
          </div>
          <div style="width: 60%;" class="masonry">
            <div class="card-2" v-for="(item, idx) in testimonies" :key="idx">
              <p class="text-center">{{ item.feedback }}</p>
              <div class="profile mt-5 d-flex" style="align-items: center">
                <div class="avatar">
                  <v-img :src="$utils.loadImage(item.image_url)" alt="" width="60" height="60" class="avatar-image" />
                </div>
                <div class="testi-profile ml-5">
                  <div class="name">{{ item.name }}</div>
                  <div class="description">
                    {{ item.job }} {{ item.place }}
                  </div>
                  <v-img v-if="item.linkedin" src="/img/home/linkedin.png" alt="" width="23" height="23"
                    @click="redirectReload(item.linkedin)" class="linkedin-image cursor-pointer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="faq" style="margin-top: 0px;">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header" style="margin-bottom: 20px;">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: -10px;">
            <v-expansion-panels flat class="faq-card" style="padding: 20px; background-color: #F1F6FF;">
              <v-expansion-panel v-for="(item, index) in questions" :key="index"
                style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)">
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow style="margin-right: 35px;" />
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active" style="margin-right: 35px;" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="btn-stoke-blue" @click="$router.push('/faq')">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
              <ArrowLong />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepCollaboration from '@/components/corporate/StepCollaboration.vue';
import Button from '../../../others/Button.vue';
import CopyIc from '@/assets/icons/career/Copy.vue';
import EmptyData from "../../../others/EmptyData.vue";
import ClockIc from '@/assets/icons/career/Clock.vue';
import LevelIc from '@/assets/icons/career/Level.vue';
import LocationIc from '@/assets/icons/career/Location.vue';
import moment from 'moment';
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import Popup from "@/components/others/Popup.vue";

export default {
  name: "DesktopCareer",
  components: {
    StepCollaboration,
    Button,
    ArrowLong,
    ClockIc,
    LevelIc,
    LocationIc,
    CopyIc,
    EmptyData,
    Popup
  },
  data() {
    return {
      filter: {
        position: "",
        work_type: "",
        level: "",
      },
      errMessage: "",
      isErr: false,
      show_alert: false,
      currentPage: 1,
      total: 1,
      questions: [],
      testimonies: [],
      usps: [
        {
          img: "/img/about/c2.png",
          name: "Peluang Karir Terbaru",
          description: "Informasi up-to-date secara berkala menyesuaikan kebutuhan industri terkini memberikan kesempatan karir bagi HiColers secara aktual",
        },
        {
          img: "/img/about/b6.png",
          name: "Kemudahan Akses",
          description: "Platform HiColleagues memudahkan pelamar dalam mencari dan mengelola lamaran hingga proses rekrutmen selesai",
        },
        {
          img: "/img/about/b4.png",
          name: "Mitra Terpercaya",
          description: "HiColleagues telah memiliki pengalaman rekrutmen yang kredibel, sehingga kami yakin dapat menghadirkan pengalaman terbaik bagi pelamar dalam mewujudkan impian karir",
        }
      ],
      workTypeList: [
        {
          name: "Full-Time",
          value: "Full-Time"
        },
        {
          name: "Part-Time",
          value: "Part-Time"
        },
        {
          name: "Freelance",
          value: "Freelance"
        },
        {
          name: "Internship",
          value: "Internship"
        },
      ],
      levelList: [
        {
          name: "Head",
          value: "Head"
        },
        {
          name: "Senior",
          value: "Senior"
        },
        {
          name: "Middle",
          value: "Middle"
        },
        {
          name: "Junior",
          value: "Junior"
        },
      ],
      steps: [
        {
          image_url: "/img/corporate/tech/bn1.png",
          title: "Temukan Lowongan Pekerjaan",
          description: "Jelajahi berbagai informasi lowongan pekerjaan dan sesuaikan dengan minat karir HiColers. Informasi yang aktual dan selaras dengan industri terkini akan ter-update secara berkala untuk kemudahan informasi bagi pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn2.png",
          title: "Lamar Pekerjaan",
          description: "Lakukan pendaftaran dan pastikan data yang HiColers kirim telah sesuai dengan benar. Data admistrasi akan digunakan sebagai acuan dalam proses seleksi kandidat pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn3.png",
          title: "Seleksi Administratif",
          description: "Data pelamar yang telah berhasil terkirim akan dilakukan kurasi oleh tim rekrutmen HiColleagues. Hal ini untuk memvalidasi data telah sesuai dengan kualifikasi atau kriteria pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn4.png",
          title: "Uji Keterampilan",
          description: "Merupakan tahap validasi kemampuan pelamar terhadap kebutuhan peran pekerjaan. Pelamar akan mengerjakan study case tertentu dan hasilnya akan menjadi pertimbangan sebagai standar kualifikasi keterampilan pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn5.png",
          title: "interview",
          description: "Apabila secara administratif dan Uji Keterampilan pelamar telah lolos, maka, akan dilakukan sesi interview baik oleh tim rekrutmen HiColleagues maupun Users. Hasil dari tahap ini akan digunakan sebagai Final Assessment pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn5.png",
          title: "Offering",
          description: "Setelah rangkaian seleksi dilakukan, apabila pelamar telah tervalidasi sesuai dengan kualifikasi yang dibutuhkan, maka tim rekrutmen HiColleagues akan memberikan penawaran terhadap peran atau okupasi yang dibutuhkan.",
        }
      ],
      listCareer: [],
    }
  },
  watch: {
    show_alert() {
      if (!this.show_alert) return;
      setTimeout(() => {
        this.show_alert = false;
      }, 3000);
    },
  },
  methods: {
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya", "_blank");
    },
    scrollToSection() {
      window.scrollTo(0, 1500)
    },
    page(act) {
      if (act === "prev") {
        if (this.currentPage <= 1) return;
        this.currentPage--;
      } else {
        if (this.currentPage >= this.total) return;
        this.currentPage++;
      }
      this.getCareer()
    },
    moment(date) {
      return moment(date).format("DD MMMM YYYY")
    },
    toCheckout(data) {
      if (new Date() > new Date(data.closed_at)) return
      this.$router.push(`/career/applicant/${data.id}`)
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    onCopy() {
      const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;
            clipboardData.writeText(window.location.href);
            this.show_alert = true;
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=CAREER&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        this.isErr = true
        this.errMessage = err.response.data.data
      }
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get('/v1/testimony?category=CAREER&is_active=true&page=1&per_page=4');
        this.testimonies = resp.data.data.testimonies;
      } catch (err) {
        this.isErr = true
        this.errMessage = err.response.data.data
      }
    },
    redirectReload(e) {
      if (e.slice(0, 5) == "https") {
        window.open(e, '_blank')
      } else {
        this.$router
          .push({ path: e })
          .then(() => { this.$router.go() })
      }
    },
    async getCareer() {
      try {
        const res = await this.$http.get(`/v1/career?position=${this.filter.position}&level=${this.filter.level}&work_type=${this.filter.work_type}&page=${this.currentPage}&per_page=9`)
        this.listCareer = res.data.data.careers
        this.total = Math.floor(res.data.data.total / 9) + 1
      } catch (err) {
        this.isErr = true
        this.errMessage = err.response.data.data
      }
    }
  },
  mounted() {
    this.getFaq()
    this.getTestimoni()
    this.getCareer()
  }
}
</script>

<style src="./career.css" scoped></style>