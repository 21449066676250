<template>
    <div style="margin-top: 63px">
        <div class="hero">
            <div class="hero-ctn">
                <h1>
                    Testimoni
                </h1>
                <p>
                    Temukan beragam informasi yang kamu butuhkan seputar program HiColleagues di sini!
                </p>
            </div>

        </div>
        <div style="background-color: #f5f6fa;" class="pb-10">
            <div class="container">
                <div class="masonry">
                    <div class="card-2" v-for="(item, idx) in testimonies" :key="idx">
                        <p class="text-center">{{ item.feedback }}</p>
                        <div class="profile mt-5 d-flex" style="align-items: center">
                            <div class="avatar">
                                <img :src="$utils.loadImage(item.image_url)" alt="" width="60" height="60"
                                    class="avatar-image" />
                            </div>
                            <div class="testi-profile ml-5">
                                <div class="name">{{ item.name }}</div>
                                <div class="description">
                                    {{ item.job }} {{ item.place }}
                                </div>
                                <v-img v-if="item.linkedin" src="/img/home/linkedin.png" alt="" width="23" height="23"
                                    @click="redirectReload(item.linkedin)" class="linkedin-image cursor-pointer" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination" v-if="total > 1">
                    <span class="page-link" @click="page('prev')">Sebelumnya</span>
                    <span :class="`page-link ${currentPage === idx ? 'active' : ''}`" v-for="idx in total"
                        :key="idx">{{ idx }}</span>

                    <span class="page-link" @click="page('next')">Selanjutnya</span>
                </div>
            </div>
        </div>
        <v-card elevation="0" class="call-center-container">
            <v-row style="padding: 40px">
                <v-col cols="7">
                    <div class="call-center-title">
                        Wujudkan Mimpi Jadi Talenta Digital Kompeten & #CiptakanMasaDepan Bersama HiColleagues!
                    </div>
                    <div class="call-center-desc">
                        Rekomendasi berbagai program Akselerasi Digital dalam Upskilling, New Skilling, & Reskilling
                        yang kredibel
                    </div>
                </v-col>
                <v-col cols="1" />
                <v-col cols="4" class="call-center-button" @click="$router.push('/')">
                    <v-btn class="btn" elevation="0"> LIHAT PROGRAM </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default {
    name: "DekstopTestimony",
    data() {
        return {
            testimonies: [],
            currentPage: 1,
            total: 1,
        }
    },
    methods: {
        async getTestimoni() {
            try {
                const resp = await this.$http.get(`/v1/testimony?category=HOME&page=${this.currentPage}&per_page=20`);
                this.testimonies = resp.data.data.testimonies;
                if (resp.data.data.total) {
                    this.total = parseInt(resp.data.data.total / 20) + 1;
                }
            } catch (err) {
                console.log(err);
            }
        },
        page(act) {
            if (act === 'prev') {
                if (this.currentPage <= 1) return
                this.currentPage--
            } else {
                if (this.currentPage >= this.total) return
                this.currentPage++
            }
            this.getTestimoni()
        }
    },
    mounted() {
        this.getTestimoni()
    }
}
</script>
<style scoped src="./testimony.css"></style>