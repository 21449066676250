<template>
  <div class="mt-10">
    <popup
      :message="notif.alertErrMessage"
      v-if="notif.isAlertErr"
      @close="notif.isAlertErr = false"
    />
    <div class="main-banner">
      <div style="width: 90%; margin-left: 10px;">
        <h2 style="line-height: 29px;">Temukan Event Menarik & Buat Digital Upskilling -mu Makin Seru!</h2>
        <p class="mt-5">
          One stop solution untuk penuhi kebutuhan tenaga kerja perusahaan
        </p>
      </div>
    </div>
    <div class="banner-content">
        <div class="text-center mt-4">
            <h2>Kenapa Harus Ikut Event HiColleagues</h2>
            <p class="mt-2">Berbagai benefit yang akan HiColers dapatkan dalam Event HiColleagues</p>
        </div>
        <div v-for="n in benefits" :key="n.id" class="content-card mr-2 ml-2">
          <div class="left-span">
            <img :src="n.image" width="70" height="70" />
          </div>
          <div>
            <h3 class="title-2">{{ n.title }}</h3>
            <p class="desc mt-2">{{ n.desc }}</p>
          </div>
        </div>
        <div class="text-center mt-4">
            <h2>Rekomendasi Event HiColleagues</h2>
            <p class="mt-2">Event menarik yang tersedia untuk HiColers</p>
            <div class="event-box mt-2" v-for="(item, idx) in events" :key="idx">
            <img
              :src="item.img_urls.mobile.thumbnail"
              alt="Content"
              class="event-box-img"
              @click="toDetail(item)"
            />
            <div class="event-ctn">
              <div @click="toDetail(item)">
                <h3 style="line-height: 20px">{{ item.name }}</h3>
                <div class="event-ctn-label">
                  <span>{{ item.category.name }}</span>
                </div>
                <div class="event-ctn-info">
                  <img src="/img/icons/date.png" alt="Date" />
                  <span class="name">{{ moment(item.session_schedules[0].date_start) }}</span>
                </div>
                <div class="event-ctn-info">
                  <img src="/img/icons/clock.png" alt="Clock" />
                  <span class="name"
                    >{{ item.session_schedules[0].start_time }} - {{ item.session_schedules[0].end_time }} WIB</span
                  >
                </div>
                <div class="event-ctn-info">
                  <img src="/img/icons/methode.png" alt="Methode" />
                  <span class="name">{{ item.method | toMethode }}</span>
                </div>
                <div class="event-ctn-info" v-if="item.instructors.length">
                  <img src="/img/icons/speaker.png" alt="Methode" />
                  <span class="name"
                    >{{ item.instructors[0].name
                    }}<span v-if="item.instructors.length > 1"
                      >, {{ item.instructors.length - 1 }}+ lainnya</span
                    ></span
                  >
                </div>
                <p class="event-price" v-if="!checkIsEarlyBird(item)">
                  {{ item.price ? toCurrency(item.price) : "GRATIS" }}
                </p>
                <div v-else class="mb-2">
                  <span class="event-price mr-2">{{ (item.price - item.discount_price) ? toCurrency(item.price - item.discount_price) : "GRATIS" }}</span><del class="discount-price">{{ toCurrency(item.price) }}</del><br>
                  <span style="font-size: 14px;"><b style="color: #162ca2; font-family: 'Poppins';">Early Bird Promo</b>! Diskon {{discountPercent(item)}}%</span>
                </div>
                <div class="event-due-date" v-if="item.expired.length">
                  <div class="event-due-date-ctn">
                    <span>{{ item.expired[0] }}</span>
                    <span>Hari</span>
                  </div>
                  <span>:</span>
                  <div class="event-due-date-ctn">
                    <span>{{ item.expired[1] }}</span>
                    <span>Jam</span>
                  </div>
                  <span>:</span>
                  <div class="event-due-date-ctn">
                    <span>{{ item.expired[2] }}</span>
                    <span>Menit</span>
                  </div>
                  <span>:</span>
                  <div class="event-due-date-ctn">
                    <span>{{ item.expired[3] }}</span>
                    <span>Detik</span>
                  </div>
                </div>
              </div>
              <div
                style="display: flex; justify-content: center; margin: 10px 0"
              >
                <Button
                :disabled="!item.expired.length"
                  name="DAFTAR"
                  width="250px"
                  @click="() => toCheckout(item)"
                />
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Button from "@/components/others/Button.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "EventMobile",
  data() {
    return {
      benefits: [
        {
          image: "/img/event/narasumber-profesional.png",
          title: "Narasumber Profesional",
          desc: "Event semakin menarik karena diisi oleh narasumber profesional & pengalaman di bidangnya",
        },
        {
          image: "/img/event/topik-ter-update.png",
          title: "Topik Ter-update",
          desc: "Topik hangat dan menarik tersedia untuk melengkapi wawasan, informasi & keterampilan HiColers",
        },
        {
          image: "/img/event/interaktif-sesi.png",
          title: "Interaktif Sesi",
          desc: "Komunikasi 2 arah antara narasumber dan peserta selama pelaksanaan event berlangsung",
        },
        {
          image: "/img/event/e-certificate.png",
          title: "E-Certificate",
          desc: "Peserta mendapatkan sertifikat Partisipan atau Penyelesain setelah event berlangsung ",
        },
        {
          image: "/img/event/harga_terjangkau.png",
          title: "Harga Terjangkau",
          desc: "Tidak perlu risau karena harga event terjangkau dan terdapat event gratis",
        },
      ],
      notif: {
        alertErrMessage: "",
        isAlertErr: false,
      },
      events: [],
    };
  },
  components: {
    Button,
    Popup
  },
  methods: {
    contactMe() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    async getTotalRegistran(id) {
      try {
        const resp = await this.$http.get(`/v1/event/${id}/registrant`);
        return resp.data.data.total_registrants  
      } catch (error) {
        console.log(error);
        return 0
      }
    },
    async toCheckout(item) {
      const total_registrants = await this.getTotalRegistran(item.id)
      if (total_registrants >= item.participant_quota && item.participant_quota !== 0) {
        this.notif.isAlertErr = true
        this.notif.alertErrMessage = "Kouta pendaftaran sudah penuh"
        return
      }
      this.$router.push("/checkout/event/"+item.id);
    },
    toDetail(item) {
      this.$router.push("/event/detail/"+item.id);
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        this.events.forEach((item) => {
          const end = new Date(item.registration_close_date);
          const now = new Date();
          const distance = end - now;
          if (distance < 0) {
            clearInterval(timer);
            item.expired = [];
            return;
          }
          days = Math.floor(distance / _day);
          hours = Math.floor((distance % _day) / _hour);
          minutes = Math.floor((distance % _hour) / _minute);
          seconds = Math.floor((distance % _minute) / _second);
          item.expired = [days, hours, minutes, seconds];
        });
      };
      timer = setInterval(showRemaining, 1000);
    },
    discountPercent(event) {
      return Math.round(((event.price - (event.price - event.discount_price))/(event.price))*100)
    },
    async getDataEvents() {
      try {
        const resp = await this.$http.get("/v1/event?is_active=true");
        this.events = resp.data.data.events.map((item) => ({
          ...item,
          session_schedules: item.session_schedules ? item.session_schedules  
            ? item.session_schedules
            : [
                {
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                },
              ] : [{
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                }],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
                desktop: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
                mobile: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
              },
          expired: [],
        }));
      } catch (err) {
        console.log(err);
      }
    },
    checkIsEarlyBird(item) {
      if (new Date(item.start_discount_date) <= new Date() && new Date(item.end_discount_date) >= new Date()) {
        return true
      } else {
        return false
      }
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    },
    scroolTo() {
      window.scrollTo(0, 1600);
    },
  },
  mounted() {
    this.getDataEvents()
    this.countDownTimer();
    window.scrollTo(0, 0);
  },
};
</script>

<style src="./event.css" scoped></style>
