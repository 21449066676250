<template>
  <div style="margin-top: 63px">
    <div class="hero">
      <div class="hero-ctn">
        <h1>Portfolio</h1>
        <p>Berbagai proyek kemitraan dalam transformasi digital berkelanjutan yang telah berhasil HiColleagues
          selenggarakan</p>
      </div>
    </div>
    <div style="background-color: #f5f6fa;">
      <div class="container">
        <div class="d-flex" style="margin-top: 21px; overflow-x: auto;">
          <div class="inactive" @click="getPortfolio(data.value)" :class="{ active: category === data.value }"
            v-for="(data, idx) in categoryList" :key="idx">
            <span>{{ data.name }}</span>
          </div>
        </div>
        <div class="card" style="padding-bottom: 60px">
          <EmptyData v-if="!portfolio || !portfolio.length" message="Belum ada portofolio saat ini" />
          <div class="items" v-for="(item, idx) in portfolio" :key="idx" @click="$router.push(`/portfolio/${item.id}`)">
            <div style="height: 240px">
              <img :src="item.image_url" alt="" style="width: 100%; height: 100%; border-radius: 20px 20px 0 0" />
            </div>
            <div class="content">
              <div style="height: 70px;">
                <h2>{{ item.program }}</h2>
              </div>
              <p class="description-class">{{ item.description }}</p>
            </div>
          </div>
          <div style="display: flex; justify-content: center;" v-if="currentPage < total">
            <Button name="MUAT LEBIH BANYAK" width="440px" @click="() => page()" />
          </div>
        </div>
      </div>
    </div>
    <v-card elevation="0" class="call-center-container">
      <v-row style="padding: 20px 8px">
        <v-col cols="12">
          <div class="call-center-title">
            Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam Transformasi Digital
            Berkelanjutan
          </div>
          <div class="call-center-desc">
            Dapatkan berbagai informasi yang kamu butuhkan melalui layanan konsultasi 24/7 Customer Service
            HiColleagues
          </div>
        </v-col>
        <v-col cols="12" class="call-center-button" @click="sendWa">
          <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Button from "../../others/Button.vue";
import EmptyData from '../../others/EmptyData.vue';

export default {
  name: "MobileAlumniStories",
  components: {
    Button,
    EmptyData
  },
  data() {
    return {
      portfolio: [],
      currentPage: 1,
      total: 1,
      category: "COLLABORATION",
      categoryList: [
        {
          name: "Corporate Collaboration",
          value: "COLLABORATION"
        },
        {
          name: "Corporate Training",
          value: "TRAINING"
        },
        {
          name: "Technology Service",
          value: "SERVICES"
        },
        {
          name: "Kampus Merdeka",
          value: "KAMPUS_MERDEKA"
        },
      ]
    }
  },
  methods: {
    sendWa() {
      window.open("https://wa.me/6281234567890", "_blank");
    },
    page() {
      this.currentPage++
      this.getPortfolio()
    },
    async getPortfolio(val) {
      if (val) {
        this.category = val
        this.portfolio = []
      }
      try {
        const resp = await this.$http.get(`/v1/project-portfolio?category=${this.category}&page=${this.currentPage}&per_page=9`)
        this.portfolio = this.portfolio.concat(resp.data.data.project_portfolios)
        if (resp.data.data.total) {
          this.total = parseInt(resp.data.data.total / 9) + 1;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getPortfolio()
  }
}
</script>

<style scoped src="./portfolio.css"></style>