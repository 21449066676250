export default {
    loadImage(image) {
        if (image && image != "-") return image
        return '/img/other/ic_profile.png'
    },
    paginate(totalData, itemsPerPage) {
        const numPages = Math.ceil(totalData / itemsPerPage);
        const pages = [];
    
        for (let i = 0; i < numPages; i++) {
            const start = i * itemsPerPage;
            const end = Math.min(start + itemsPerPage, totalData);
            pages.push({ page: i + 1, start: start, end: end });
        }
    
        return pages[pages.length-1].page;
    },
    scrollTop() {
        window.scrollTo(0, 0)
    }
}