<template>
  <div>
    <DesktopTechnologyService v-if="!isMobile" />
    <MobileTechnologyService v-if="isMobile" />

  </div>
</template>

<script>
import DesktopTechnologyService from '@/components/corporate/technology-service/desktop/TechnologyService'
import MobileTechnologyService from '@/components/corporate/technology-service/mobile/TechnologyService'
export default {
  components: {
    DesktopTechnologyService,
    MobileTechnologyService
  },
  metaInfo() {
      const thisPath = window.location.href
      return {
        title: "Technology Service",
        htmlAttrs: {
          lang: "id",
          amp: true,
        },
        meta: [
          { charset: "utf-8" },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name:"keywords", content: "Technology Service,HiColleagues,Technology Service HiColleagues"},
          { name: "description", content: "Menawarkan solusi dan inovasi terbaik untuk mendorong transformasi digital…"},
        ],
        link: [
          {
            rel: 'canonical',
            href: thisPath
          }
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Technology Service",
              "url": thisPath,
              "potentialAction": {
                "@type": "SearchAction",
                "target": thisPath
              }
            }
          }
        ]
      };
    },
  data() {
    return {
      isMobile: false
    }
  },
  methods: {
    handleView() {
      this.isMobile = screen.width <= 990
    }
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView();
  }
}
</script>