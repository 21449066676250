<template>
  <div class="container-size" style="margin-top: 63px">
    <!-- HERO SECTION -->
    <popup :message="alertErrMessage" v-if="isAlertErr" :isError="isError" @close="isAlertErr = false" />
    <div class="hero">
      <div class="content">
        <h1>Langkah Efisien untuk Temukan Talenta Digital Kompeten</h1>
        <p>
          Permudah cara pencarian kandidat talenta digital sesuai dengan kebutuhan perusahaan Anda melalui proses yang
          terstruktur dan kredibel
        </p>
        <div class="btn-cta">
          <button class="now" @click="scrollToSection('collab')">KOLABORASI SEKARANG</button>
          <button class="call" @click="sendToWa">HUBUNGI KAMI</button>
        </div>
      </div>
      <div class="card-img">
        <img src="/img/corporate/talent-service/main.png" width="520" height="" alt=""
          style="border-radius: 0 0 0 120px;" />
      </div>
    </div>
    <!-- Clients Section -->
    <div class="container-fade">
      <div class="left-fade"></div>
      <div class="right-fade"></div>
    </div>
    <div class="creditbility">
      <div class="partner">
        <h1>HiColleagues telah Dipercaya Perusahaan Ternama</h1>
        <div class="card" style="overflow-x: none !important;">
          <div v-for="(partner, index) in partners" :key="index">
            <div class="logo-card-partner-2">
              <img :src="partner.img_url_web" width="160" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CORPORATE COLLABORATION -->
    <div class="product-collaboration">
      <h1 style="margin: 0;">Optimasi Perusahaan melalui Talenta Digital Kompeten</h1>
      <p style="margin: 0; margin-bottom: 30px;">
        Perluas kerjasama kemitraan melalui program unggulan HiColleagues dan berikan dampak ke peningkatan performa
        bisnis Anda
      </p>
      <img src="/img/corporate/talent-service/line-cv.png" height="" alt=""
        style="width: 78%; display: block; margin: auto; margin-bottom: 20px;" />
      <div class="card-product">
        <div class="product" v-for="(product, index) in products" :key="index">
          <img :src="product.icon" width="60" height="60" alt="" />
          <h4>{{ product.title }}</h4>
          <p>{{ product.desc }}</p>
        </div>
      </div>
    </div>
    <div class="bg-linear">
      <div class="layanan-requirement">
        <h2 class="pt-5 mb-8 text-center">Layanan Perekrutan HiColleagues</h2>
        <div class="layanan-requirement-card">
          <div class="card-list" v-for="(data, idx) in trainData" :key="idx">
            <div class="icon">
              <components :is="data.icon"></components>
            </div>
            <h2 class="text-center">{{ data.name }}</h2>
            <p>{{ data.desc }}</p>
            <h3 class="mt-5">{{data.quest}}</h3>
            <ul>
              <li v-for="(data2, idx2) in data.list" :key="idx2">{{ data2 }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- USP -->
    <div class="usp">
      <div class="card-usp">
        <h1>Mengapa Memilih HiColleagues sebagai Mitra Talent as a Service</h1>
        <p>
          HiColleagues telah membuktikan melalui
          <span> 50+ layanan kerjasama kemitraan perusahaan yang telah dilaksanakan</span> dan memberi dampak positif
          terhadap akselerasi performa bisnis mitra
        </p>
        <div class="card-img">
          <img src="/img/corporate/talent-service/main2.png" alt="" />
        </div>
      </div>
      <div class="usp-container">
        <div class="card-usp" v-for="(usp, index) in usps" :key="index">
          <div class="title-container">
            <components class="svg-comment" :is="usp.icon" width="60" height="60"></components>
            <h3>{{ usp.title }}</h3>
          </div>
          <div class="content">
            <p>{{ usp.desc }}</p>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <div class="bg-linear">
      <!-- Langkah Kolaborasi -->
      <StepCollaboration title="Langkah Kolaboratif Kemitraan" :steps="steps" bgColor="white" />
      <div class="creditbility">
        <div class="partner">
          <h1 class="mb-4">Tren Okupasi Talenta Digital Saat Ini</h1>
          <div class="card" style="overflow-x: none !important;">
            <div v-for="(partner, index) in ocupation1" :key="index">
              <div class="logo-card-partner-2" style="width: max-content;">
                <div class="box-1">
                  <div><img :src="partner.img" alt=""></div>
                  <p>{{ partner.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="creditbility" style="padding-bottom: 120px; margin-top: -40px;">
        <div class="partner">
          <div class="card" style="overflow-x: none !important;">
            <div v-for="(partner, index) in ocupation2" :key="index">
              <div class="logo-card-partner-3" style="width: max-content;">
                <div class="box-1">
                  <div><img :src="partner.img" alt=""></div>
                  <p>{{ partner.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-row class="d-flex justify-center align-center mt-6">
            <v-btn elevation="0" class="btn-apply" @click="scrollToSection('collab')">MULAI PEREKRUTAN</v-btn>
      </v-row>
    </div>

    <!-- Form Pendaftaran Kolaborasi -->
    <div class="form-konsultasi mt-5" id="collab">
      <div class="header text-center">
        <h1>Formulir Pendaftaran Konsultasi</h1>
      </div>
      <div class="sub-heading mt-1 text-center">
        <p>
          Langkah tepat optimasi bisnis melalui layanan Corporate Service
          bersama HiColleagues
        </p>
        <v-form class="text-start" style="margin-top: 60px">
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="company">Nama Perusahaan<span
                  style="color: rgba(184, 15, 10, 1)">*</span></label>
              <v-text-field class="form-input text-form" name="company" v-model="form.corporate_name" outlined
                placeholder="Masukkan Nama Perusahaan"></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="website">Website Perusahaan</label>
              <v-text-field class="form-input text-form" v-model="form.web_url" name="website" outlined
                placeholder="Masukkan Alamat Website Perusahaan">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="pic-name">Nama Lengkap PIC<span
                  style="color: rgba(184, 15, 10, 1)">*</span></label>
              <v-text-field class="form-input text-form" name="pic-name" v-model="form.full_name" outlined
                placeholder="Masukkan Nama Lengkap PIC"></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="occupation">Okupasi PIC</label>
              <v-text-field class="form-input text-form" name="occupation" v-model="form.job_pic" outlined
                placeholder="Masukkan Okupasi PIC">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="email">Email PIC<span style="color: rgba(184, 15, 10, 1)">*</span></label>
              <v-text-field class="form-input text-form" name="email" v-model="form.email" outlined
                placeholder="Masukkan Email PIC"></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="phone-number">No. Whatsapp PIC<span
                  style="color: rgba(184, 15, 10, 1)">*</span></label>
              <v-text-field class="form-input text-form" name="phone-number" outlined
                placeholder="Masukkan No. Whatsapp PIC" type="number" v-model="form.phone_number" hide-spin-buttons>
                <template v-slot:prepend-inner>
                  <v-row class="d-flex align-center mt-0 ml-7">
                    <img src="/img/other/flag-indonesia.png" alt="" width="30" height="20"
                      style="box-shadow: 2px 2px 3px 1px rgba(13, 28, 113, 0.1)" />
                    <span style="color: #575966" class="mx-3">+62</span>
                  </v-row>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="form-collab">
              <label class="form-label" for="collaboration">Proyeksi Kolaborasi/Kemitraan</label>
              <v-text-field class="form-input text-form" solo flat background-color="rgba(247, 249, 253, 1)"
                placeholder="Talent as a service" disabled />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center mt-3">
            <v-checkbox class="form-input custom-checkbox" v-model="is_confirm">
              <template v-slot:label>
                <p class="label-checkbox">
                  Dengan mengirim formulir ini saya menyetujui<a href="/term-condition">
                    Syarat & Ketentuan </a>untuk melakukan kemitraan/kolaborasi bersama HiColleagues.
                </p>
              </template>
            </v-checkbox>
          </v-row>
          <v-row class="d-flex justify-center align-center mt-6">
            <v-btn elevation="0" :class="`btn-kirim ${is_confirm ? 'confirm' : 'reject'}`" @click="onSubmit">KIRIM
            </v-btn>
          </v-row>
        </v-form>
      </div>
    </div>
    <div class="faq" style="margin-top: 40px;">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: 15px;">
            <v-expansion-panels flat class="faq-card" style="padding: 10px; background-color: #F1F6FF;">
              <v-expansion-panel v-for="(item, index) in questions" :key="index"
                style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)">
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow />
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '@/assets/icons/Arrow.vue';
import StepCollaboration from '../../StepCollaboration.vue';
import Popup from "@/components/others/Popup.vue";
import PeopleTrain from '@/assets/icons/corporate/PeopleTrain.vue';
import CardTrain from '@/assets/icons/corporate/CardTrain.vue';
import M1Talent from '@/assets/icons/corporate/M1Talent.vue';
import M2Talent from '@/assets/icons/corporate/M2Talent.vue';
import M3Talent from '@/assets/icons/corporate/M3Talent.vue';
import M4Talent from '@/assets/icons/corporate/M4Talent.vue';
import M5Talent from '@/assets/icons/corporate/M5Talent.vue';
import M6Talent from '@/assets/icons/corporate/M6Talent.vue';

export default {
  components: {
    StepCollaboration,
    Popup,
    Arrow,
    CardTrain,
    PeopleTrain,
    M1Talent,
    M2Talent,
    M3Talent,
    M4Talent,
    M5Talent,
    M6Talent,
  },
  name: "TalentAsServiceDesktop",
  data() {
    return {
      products: [
        {
          icon: "/img/corporate/talent-service/c1.png",
          title: "Talent Sourcing",
          desc: "Pencarian kandidat potensial secara masif yang selaras kebutuhan mitra dengan didukung metode sourcing dan tim yang memiliki pengalaman dengan akses database talenta terlengkap.",
        },
        {
          icon: "/img/corporate/talent-service/c2.png",
          title: "Talent Screening",
          desc: "Proses screening yang komprehensif demi menjaring talenta digital terbaik melalui aspek kompetensi teknis, pengalaman relevan, motivasi, budaya kerja, serta kecocokan dengan deskripsi pekerjaan.",
        },
        {
          icon: "/img/corporate/talent-service/c3.png",
          title: "Assesment",
          desc: "Pencarian kandidat melalui rangkaian metode asesmen seperti tes keterampilan, wawancara teknis, studi kasus dalam mengukur potensi kandidat sesuai kualifikasi yang dibutuhkan.",
        },
        {
          icon: "/img/corporate/talent-service/c4.png",
          title: "Recommendation",
          desc: "Melalui hasil asesmen, kandidat talenta terbaik akan direkomendasikan sesuai dengan kebutuhan bisnis Anda, disertai profil lengkap, portfolio, dan informasi pendukung lainnya.",
        },
        {
          icon: "/img/corporate/talent-service/c5.png",
          title: "Contract Management",
          desc: "HiColleagues akan menangani seluruh proses kontrak kerja seperti negosiasi kompensasi, penyusunan kontrak, perizinan, onboarding, dan pengelolaan talenta selama masa kontrak sesuai regulasi.",
        },
      ],
      partners: [],
      usps: [
        {
          icon: M1Talent,
          title: "Proses Rekrutmen Efisien",
          desc: "Proses rekrutmen dilakukan dengan tepat oleh tim profesional dan didukung metode pencarian kandidat yang komprehensif.",
        },
        {
          icon: M2Talent,
          title: "Jaminan Kepatuhan Regulasi",
          desc: "Kepatuhan regulasi HiColleagues memastikan kepatuhan terhadap semua regulasi dan peraturan ketenagakerjaan yang berlaku.",
        },
        {
          icon: M3Talent,
          title: "Profesionalisme Manajemen",
          desc: "Pengelolaan SDM profesional HiColleagues menangani seluruh aspek pengelolaan SDM seperti penggajian, tunjangan, administrasi, dan lainnya secara profesional.",
        },
        {
          icon: M4Talent,
          title: "Solusi Talenta Digital Fleksibel",
          desc: "Fleksibilitas maksimal dalam penyediaan SDM untuk memperoleh talenta terbaik dengan keterampilan dan skala yang dapat disesuaikan sesuai kebutuhan bisnis Anda.",
        },
        {
          icon: M5Talent,
          title: "Talenta Digital Berkualitas",
          desc: "HiColleagues telah menemukan ribuan talenta digital berkualitas yang berhasil direkrut oleh perusahaan-perusahaan terkemuka di Indonesia.",
        },
        {
          icon: M6Talent,
          title: "Akses Talenta Kompeten",
          desc: "Dapatkan akses ke jaringan talenta profesional yang luas dan terverifikasi di berbagai bidang keahlian.",
        },
      ],
      steps: [
        {
          image_url: "/img/corporate/talent-service/bn3.png",
          title: "Identifikasi Kebutuhan Talenta",
          description: "Sampaikan kebutuhan talenta digital Anda secara rinci melalui proses observasi. Tim kami akan menghubungi Anda untuk membahas lebih lanjut dan memastikan pemahaman yang jelas tentang kebutuhan bisnis Anda.",
        },
        {
          image_url: "/img/corporate/talent-service/bn4.png",
          title: "Penelusuran dan Seleksi Talenta",
          description: "Penyaringan menyeluruh pada database talenta kami untuk menemukan talenta digital yang paling sesuai dengan kebutuhan Anda, diikuti dengan proses seleksi berdasarkan keahlian teknis dan budaya perusahaan, meliputi asesmen, wawancara teknis, dan studi kasus",
        },
        {
          image_url: "/img/corporate/talent-service/bn5.png",
          title: "Presentasi Talenta Terbaik",
          description: "Kami akan menyajikan profil lengkap talenta digital terpilih, termasuk portofolio, riwayat pengalaman kerja, dan kualifikasi lainnya yang dapat membantu Anda dalam mengevaluasi kesesuaian mereka dengan proyek Anda.",
        },
        {
          image_url: "/img/corporate/talent-service/bn6.png",
          title: "Penawaran dan Perjanjian Kerja",
          description: "Setelah menentukan kandidat talenta yang tepat, kami akan memfasilitasi penawaran dan perjanjian kerja yang sesuai dengan kesepakatan bersama. Kami memastikan kompensasi layak, ketentuan kerja jelas, dan perlindungan hak bagi Anda dan talenta.",
        },
        {
          image_url: "/img/corporate/talent-service/bn7.png",
          title: "Onboarding dan Integrasi",
          description: "Talenta terpilih mengikuti onboarding dan diintegrasikan ke dalam tim atau proyek Anda. Tim kami mendampingi selama proses ini untuk memfasilitasi integrasi yang baik agar talenta dapat berkontribusi efektif sejak awal.",
        },
        {
          image_url: "/img/corporate/talent-service/bn8.png",
          title: "Evaluasi Berkelanjutan",
          description: "Kami mengevaluasi kinerja talenta secara berkala. Menganalisis data untuk menyusun strategi lebih efektif dalam memenuhi kebutuhan talenta dan mencapai tujuan bisnis Anda.",
        },
      ],
      ocupation1: [
        {
          img: "/img/corporate/talent-service/k1.png",
          title: "Cyber Security"
        },
        {
          img: "/img/corporate/talent-service/k2.png",
          title: "Database Administrator"
        },
        {
          img: "/img/corporate/talent-service/k2.png",
          title: "Data Engineer"
        },
        {
          img: "/img/corporate/talent-service/k3.png",
          title: "Data Analyst"
        },
        {
          img: "/img/corporate/talent-service/k4.png",
          title: "Mobile Developer"
        },
        {
          img: "/img/corporate/talent-service/k11.png",
          title: "Scrum Master"
        },
      ],
      ocupation2: [
        {
          img: "/img/corporate/talent-service/k5.png",
          title: "Product Manager"
        },
        {
          img: "/img/corporate/talent-service/k6.png",
          title: "Product Design"
        },
        {
          img: "/img/corporate/talent-service/k7.png",
          title: "Quality Assurance"
        },
        {
          img: "/img/corporate/talent-service/k8.png",
          title: "Risk Analyst"
        },
        {
          img: "/img/corporate/talent-service/k9.png",
          title: "System Administrator"
        },
        {
          img: "/img/corporate/talent-service/k12.png",
          title: "Web Developer"
        },
      ],
      trainData: [
        {
          icon: CardTrain,
          name: "IT Outsourcing",
          desc: "Kami menyediakan dan mengelola talenta digital dengan beragam level dan role position di bidang teknologi digital yang siap mendukung optimasi dan produktivitas bisnis perusahaan Anda",
          quest: "Mengapa pilih IT Outsourcing?",
          list: ['Perusahaan dapat fokus melakukan aktivitas bisnis utama', 'Perusahaan tidak perlu menyediakan fasilitas dan tunjangan', 'Perusahaan akan mendapatkan talenta digital yang berkualitas', 'Perusahaan tidak perlu repot melakukan proses rekrutmen', 'Menghemat Biaya Operasional Signifikan', 'Akses Talenta Digital dan Teknologi Baru']
        },
        {
          icon: PeopleTrain,
          name: "IT Head Hunting",
          desc: "Kami menyediakan layanan perekrutan dan menemukan talenta digital berkualitas serta profesional melalui mekanisme perekrutan yang kompatibel dan terstruktur untuk menjawab kebutuhan perusahaan Anda",
          quest: "Mengapa pilih Head Hunting?",
          list: ['Efisiensi biaya dan waktu', 'Identifikasi kandidat yang cocok dengan posisi yang tersedia', 'Menemukan talenta digital yang kompatibel', 'Mempercepat proses sourcing dan screening', 'Mendapatkan garansi pergantian talenta digital sesuai dengan kesepakatan']
        },
      ],
      questions: [],
      alertErrMessage: "",
      isAlertErr: false,
      is_confirm: false,
      isError: false,
      form: {
        full_name: "",
        email: "",
        phone_number: "",
        gender: "",
        corporate_name: "",
        company_field: "",
        program: "",
        total_student: 0,
        notes: "",
        category: "TALENT_SERVICE",
        web_url: "",
        job_pic: ""
      },
    };
  },
  methods: {

    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    duplicateOcupation1() {
      this.ocupation1 = this.ocupation1.concat(this.ocupation1, this.ocupation1, this.ocupation1);
    },
    duplicateOcupation2() {
      this.ocupation2 = this.ocupation2.concat(this.ocupation2, this.ocupation2, this.ocupation2);
    },
    duptlicatePartners() {
      this.partners = this.partners.concat(this.partners, this.partners, this.partners);
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get("/v1/logo?category=TALENT_SERVICE")
        this.partners = resp.data.data.logos
        this.duptlicatePartners();
      } catch (err) {
        console.log(err);
      }
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=TALENT_SERVICE&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    async onSubmit() {
      if (!this.is_confirm) {
        return
      }
      if (!this.form.corporate_name || !this.form.full_name || !this.form.job_pic || !this.form.email || !this.form.phone_number || !this.form.category || !this.form.web_url) {
        this.alertErrMessage = "Harap isi semua form yang tersedia"
        this.isAlertErr = true
        this.isError = true
        return
      }
      try {
        await this.$http.post('/v1/corporate-registration', this.form)
        this.alertErrMessage = "Success"
        this.isAlertErr = true
        this.isError = false
        this.clearForm()
      } catch {
        this.alertErrMessage = "Error saat submit data"
        this.isAlertErr = true
        this.isError = true
      }
    },
    clearForm() {
      this.is_confirm = false
      this.form.full_name = ""
      this.form.email = ""
      this.form.phone_number = ""
      this.form.corporate_name = ""
      this.form.category = ""
      this.form.web_url = ""
      this.form.job_pic = ""
    },
  },
  mounted() {
    this.getClientLogo()
    this.getFaq()
    this.duplicateOcupation1()
    this.duplicateOcupation2()
  }
};
</script>

<style src="./index.css" scoped></style>
