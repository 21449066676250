<template>
  <div style="margin-top: 60px">
    <!-- <div class="bg" ref="ctn_head">
            <div class="d-flex container">
                <div class="d-flex left-banner">
                    <div>
                        <div>
                            <div class="d-flex" v-if="!isPrakerja">
                                <div class="btn-white-oval">Intensive Class</div>
                                <div class="btn-white-oval">Boost Further</div>
                            </div>
                            <div class="d-flex" v-if="isPrakerja">
                                <div class="btn-white-oval"><img src="/img/class/prakerja.png" width="100px"></div>
                                <div class="btn-white-oval">Webinar</div>
                            </div>
                            <span class="text-banner">{{classDetail.name}}</span>
                            <img src="/img/other/purple-line.png" width="265" class="line">
                        </div>
                        <p style="margin-top:30px">Tingkatkan skill menjadi profesional dengan Job Guarantee Program dan dapatkan unlimited career support sampai kamu diterima bekerja.</p>

                        <v-btn depressed color="#162CA2" @click="goToCheckoutPage" style="margin-top:58px;padding:25px 105px;" class="btn-radius">
                            <span class="text-capitalize font-weight-bold white--text" @click="goToCheckoutPage">DAFTAR SEKARANG</span>
                        </v-btn>
                    </div>
                </div>
                <div>
                    <img :src="classDetail.image_url" class="img-banner">
                </div>
            </div>
        </div> -->
    <div class="head-class" ref="ctn_head">
      <div class="left">
        <div class="d-flex" v-if="!isPrakerja">
          <div class="btn-white-oval">Intensive Class</div>
          <div class="btn-white-oval">Boost Further</div>
        </div>
        <div class="d-flex" v-if="isPrakerja">
          <div class="btn-white-oval">
            <img src="/img/class/prakerja.png" width="100px" />
          </div>
          <div class="btn-white-oval align-items-center">Webinar</div>
        </div>
        <p class="text-banner">{{ classDetail.name }}</p>
        <img src="/img/other/white-line.png" width="265" class="line" />
        <p class="description-class">
          Tingkatkan skill menjadi profesional dengan Job Guarantee Program dan
          dapatkan unlimited career support sampai kamu diterima bekerja.
        </p>
        <v-btn
          depressed
          color="#FFDE59"
          @click="goToCheckoutPage"
          class="button-daftar"
        >
          <span
            class="text-capitalize font-weight-bold blue-text"
            @click="goToCheckoutPage"
            >DAFTAR SEKARANG</span
          >
        </v-btn>
      </div>
      <img :src="classDetail.image_url" class="img-banner" />
    </div>
    <div class="box-radius">
      <div
        v-for="n in menuPublicClass"
        :key="n.id"
        class="menu-detail"
        @click="clickActiveMenu(n.layout, n.position)"
      >
        <span style="font-family: 'Poppins'">{{ n.name }}</span>
        <div :class="{ linesmall: layout === n.layout }"></div>
      </div>

      <v-btn
        depressed
        color="#162CA2"
        @click="goToCheckoutPage"
        style="margin-top: 39px; padding: 25px 59px"
        class="btn-radius"
      >
        <span class="text-capitalize font-weight-bold white--text btn-radius"
          >DAFTAR SEKARANG</span
        >
      </v-btn>
    </div>

    <div class="container-parent" style="margin-top: -320px" ref="info_kelas">
      <div class="container-detail">
        <span>Info Kelas</span>
        <h5 class="title">Deskripsi Program</h5>
        <p class="desc">{{ classDetail.description }}</p>

        <h5 class="title" style="margin-top: 40px; margin-bottom: 20px">
          Mekanisme Kelas
        </h5>
        <div class="d-flex">
          <div class="stroke-yellow-box">
            <img
              src="/img/class/ic_level.png"
              style="width: 60px; height: 60px"
            />
            <div class="title-mechanism"><span>Level</span></div>
            <div class="sub-title-mechanism">
              <span>{{ classDetail.level }}</span>
            </div>
          </div>

          <div class="stroke-yellow-box">
            <img
              src="/img/class/ic_method.png"
              style="width: 60px; height: 60px"
            />
            <div class="title-mechanism"><span>Metode</span></div>
            <div class="sub-title-mechanism"><span>Online / Offline</span></div>
          </div>

          <div class="stroke-yellow-box">
            <img
              src="/img/class/ic_session.png"
              style="width: 60px; height: 60px"
            />
            <div class="title-mechanism"><span>Sesi Pertemuan</span></div>
            <div class="sub-title-mechanism">
              <span>{{ classDetail.session_total }} sesi</span>
            </div>
          </div>

          <div class="stroke-yellow-box">
            <img
              src="/img/class/ic_date.png"
              style="width: 60px; height: 60px"
            />
            <div class="title-mechanism"><span>Hari & Jam Pertemuan</span></div>
            <div class="sub-title-mechanism">
              <span>{{ classDetail.days }}<br />{{ classDetail.hours }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="bg-container"
      style="margin-bottom: 50px; background: #f1f6ff"
      ref="materi_silabus"
    >
      <div class="container-detail" style="margin-top: 45px; padding: 40px 0px">
        <span>Materi Silabus</span>
        <h5 class="title">Apa yang kamu pelajari</h5>

        <!-- <v-list style="background: #f1f6ff; margin-bottom: 65px">
          <v-list-group
            v-for="item in classDetail.syllabuses"
            :key="item.session"
            no-action
            active-class="bg-rounded"
            class="bg-rounded"
          >
            <template v-slot:activator>
              <v-list-item-content
                class="font-weight-bold"
                style="margin-bottom;color: #36373f;"
              >
                <v-list-item-title
                  class="custom-text-size"
                  v-text="item.session"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item-content style="padding-left: 15px; color: #36373f">
              <v-list-item-title
                class="custom-desc-text-size"
                v-html="item.description"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-group>
        </v-list> -->
        <div
          v-for="item in classDetail.syllabuses"
          :key="item.session"
          class="box-steps"
        >
          <h4>{{ item.session }}</h4>
          <div v-html="item.description"></div>
        </div>
      </div>
    </div>

    <div class="container-parent" style="padding: 30px 0px" ref="benefit">
      <div class="container-detail">
        <span>Benefit</span>
        <h5 class="title">Apa Saja yang Peserta Dapatkan</h5>

        <BenefitPublicTraining style="margin-bottom: 80px" v-if="!isPrakerja" />
        <BenefitPrakerja style="margin-bottom: 80px" v-if="isPrakerja" />

        <span>Tujuan Pembelajaran</span>
        <h5 class="title">Goal pelatihan yang didapatkan</h5>
        <p
          style="margin-top: 30px; margin-bottom: 90px"
          v-html="classDetail.learning_objective"
        ></p>

        <span>Target Peserta</span>
        <h5 class="title">Kelas ini Cocok untuk</h5>
        <ul>
          <li>Pendidikan minimal SMA atau sederajat</li>
        </ul>
      </div>
    </div>

    <div
      class="bg-container"
      style="background: #fdfae7; padding: 30px 0px"
      v-if="!isPrakerja"
      ref="biaya_kelas"
    >
      <div class="container-detail">
        <span>Biaya Kelas</span>
        <h5 class="title">Pilih Tipe Kelas sesuai Minatmu</h5>

        <v-row class="mt-5">
          <v-col>
            <v-card class="black--text class-type-div" style="padding: 60px">
              <div class="class-name">
                <span style="font-weight: 700; font-size: 26px">
                  KELAS PUBLIC
                </span>
                <img
                  src="/img/other/purple-line.png"
                  width="108"
                  class="line"
                />
              </div>
              <div
                v-for="n in publicClass"
                :key="n.message"
                style="display: flex; margin-top: 17px"
              >
                <img
                  src="/img/other/ic_checklist.png"
                  width="25px"
                  height="25px"
                />
                <span class="text-class-type">{{ n.message }}</span>
              </div>

              <v-btn
                depressed
                color="#FFDE59"
                @click="goToCheckoutPage"
                style="margin-top: 58px; padding: 20px 105px"
                class="btn-radius"
              >
                <span
                  class="text-capitalize font-weight-bold"
                  style="color: #162ca2"
                  >LIHAT KELAS</span
                >
              </v-btn>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="black--text class-type-div" style="padding: 60px">
              <div class="class-name">
                <span style="font-weight: 700; font-size: 26px">
                  KELAS PRIVATE
                </span>
                <img src="/img/other/red-line.png" width="108" class="line" />
              </div>
              <div
                v-for="n in privateClass"
                :key="n.message"
                style="display: flex; margin-top: 17px"
              >
                <img
                  src="/img/other/ic_checklist.png"
                  width="25px"
                  height="25px"
                />
                <span class="text-class-type">{{ n.message }}</span>
              </div>

              <v-btn
                depressed
                color="#162CA2"
                @click="goToCheckoutPage"
                style="margin-top: 58px; padding: 20px 105px"
                class="btn-radius"
              >
                <span class="text-capitalize font-weight-bold white--text"
                  >LIHAT KELAS</span
                >
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      class="container-parent"
      style="margin: 90px auto"
      ref="alurPendaftaran"
    >
      <div class="container-detail">
        <span>Alur Pendaftaran</span>
        <h5 class="title">Satu Langkah Menuju Impianmu</h5>

        <StepOfPublicTraining />
      </div>
    </div>

    <!-- <div style="background: #F5F6F9;padding-top:30px;padding-bottom:90px">
            <div class="container-detail">
                <span>FAQ</span>
                <h5 class="title">Pertanyaan Seputar Program</h5>
                
                <v-list style="background: #F5F6F9;margin-bottom:65px;">
                    <v-list-group
                        v-for="item in faq"
                        :key="item.title"
                        v-model="item.active"
                        :prepend-icon="item.action"
                        no-action
                        active-class="bg-rounded"
                        class="bg-rounded"
                    >
                        <template v-slot:activator>
                        <v-list-item-content class="font-weight-bold" style="margin-bottom;color: #36373f;">
                            <v-list-item-title class="custom-text-size" v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                        </template>

                        <v-list-item-content style="padding-left:15px;color: #36373f;">
                            <v-list-item-title class="custom-desc-text-size" v-text="item.description"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-group>
                </v-list>

                <div style="text-align:center"><span>Tidak ada pertanyaan yang ingin kamu tanyakan di atas?</span></div>
                <div class="btn-stoke-blue" style="margin-top:15px;" @click="goToCustomerService">
                    <span>PELAJARI LEBIH LANJUT</span>
                </div>
            </div>
        </div> -->

    <div class="bg-gradient-blue" style="margin-top: -50px">
      <div style="font-weight: 600; font-size: 32px; color: #fff">
        <span
          >Siap tingkatkan skill untuk berkarir jadi Digital Talent
          profesional?</span
        >
      </div>
      <div
        class="d-flex"
        style="justify-content: center; align-content: center; margin-top: 40px"
      >
        <div
          class="bg-gradient-yellow"
          @click="goToCheckoutPage"
        >
          <span class="text-blue">DAFTAR SEKARANG</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import goTo from "vuetify/lib/services/goto";
import BenefitPublicTraining from "@/components/public-training/BenefitPublicTraining";
import StepOfPublicTraining from "@/components/public-training/StepOfPublicTraining";
export default {
  components: {
    BenefitPublicTraining,
    StepOfPublicTraining,
  },
  data() {
    return {
      publicClass: [
        { message: "Lebih dari satu peserta (max 10)" },
        { message: "Jadwal kelas telah ditentukan" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      privateClass: [
        { message: "Hanya satu peserta" },
        { message: "Dapat menentukan jadwal kelas" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      faq: [
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
      ],
      menuPublicClass: [
        { name: "Info Kelas", id: 1, position: 0, layout: "info-kelas" },
        {
          name: "Materi Silabus",
          id: 2,
          position: 0,
          layout: "materi-silabus",
        },
        { name: "Benefit", id: 3, position: 0, layout: "benefit" },
        // {name: "Path Career", id: 4, position: 700},
        { name: "Biaya Kelas", id: 4, position: 0, layout: "biaya-kelas" },
        {
          name: "Alur Pendaftaran",
          id: 5,
          position: 0,
          layout: "alur-pendaftaran",
        },
        // {name: "FAQ", id: 6, position: 4700},
      ],
      isPrakerja: false,
      classDetail: {},
      layout: "info-kelas",
    };
  },
  methods: {
    clickActiveMenu(e, p) {
      this.layout = e;
      console.log(p);
      goTo(Number(p + 10));
    },
    goToCheckoutPage() {
      var classType = "PUBLIC_CLASS";
      if (this.$route.query.tipe_kelas == "prakerja") {
        classType = "PRAKERJA_CLASS";
      } else if (this.$route.query.tipe_kelas == "public-bootcamp") {
        classType = "Kelas Bootcamp";
      }

      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: classType,
        days: this.classDetail.days,
        methode: this.classDetail.method,
        category_id: this.classDetail.category_id,
      });

      localStorage.clear();
      localStorage.setItem("checkout", req);
      window.location.href = "/checkout";
    },
    goToCustomerService() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya";
    },
    mappingHeightOfElement() {
      let point = this.$refs.ctn_head.clientHeight;
      this.menuPublicClass[0].position = point;
      point += this.$refs.info_kelas.clientHeight;
      this.menuPublicClass[1].position = point;
      point += this.$refs.materi_silabus.clientHeight;
      this.menuPublicClass[2].position = point;
      point += this.$refs.benefit.clientHeight;
      this.menuPublicClass[3].position = point;
      if (!this.isPrakerja) {
        point += this.$refs.biaya_kelas.clientHeight;
      }
      this.menuPublicClass[4].position = point;
      point += this.$refs.alurPendaftaran.clientHeight;
    },
    async getDetailClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class-detail/" + this.$route.params.slugname)
        .then((response) => {
          this.classDetail = response.data.data.class_detail;
          if (this.classDetail.category_id === 5) {
            this.$router.push("/class/bootcamp/" + this.$route.params.slugname);
          }
          // this.getSchedule(this.classDetail.session_total, this.classDetail.category_id)
          setTimeout(() => {
            this.mappingHeightOfElement();
          }, 1000);
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
    handleScroll() {
      this.menuPublicClass.forEach((item) => {
        if (item.position <= window.scrollY) {
          this.layout = item.layout;
        }
      });
    },
  },
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.getDetailClass();
    if (this.$route.query.tipe_kelas == "prakerja") {
      this.isPrakerja = true;
    }
  },
};
</script>

<style src="./css/detailClass.css" scoped></style>
