<template>
  <div style="padding-top: 60px">
    <div>
      <div class="head-class" ref="ctn_head">
        <div class="left">
          <div class="d-flex">
            <div class="btn-white-oval font-poppins">Bootcamp</div>
            <div class="btn-white-oval font-poppins">
              {{ classDetail.method ? classDetail.method : "Oflline/Online" }}
            </div>
          </div>

          <p class="text-banner">{{ classDetail.name }}</p>
          <p class="description-class">
            {{
                classDetail.schedules.length
                  ? formatDate(classDetail.schedules[0].start_date)
                  : ""
              }}
          </p>
          <p class="description-class">
            Tingkatkan skill menjadi profesional dengan Job Guarantee Program
            dan dapatkan unlimited career support sampai kamu diterima bekerja.
          </p>
          <v-btn depressed color="#FFDE59" @click="goToCheckoutPage" class="button-daftar">
            <span class="text-capitalize font-weight-bold blue-text font-poppins" @click="goToCheckoutPage">DAFTAR
              SEKARANG</span>
          </v-btn>
          <v-btn depressed color="#FFFFFF" @click="sendToWa" class="button-daftar" style="margin-left: 30px">
            <span class="text-capitalize font-weight-bold blue-text font-poppins">KONSULTASI GRATIS</span>
          </v-btn>
        </div>
        <img :src="classDetail.image_url" class="img-banner-1" />
      </div>
      <div class="box-radius">
        <div v-for="n in menuPublicClass" :key="n.id" class="menu-detail"
          @click="clickActiveMenu(n.layout, n.position)">
          <span>{{ n.name }}</span>
          <div :class="{ linesmall: layout === n.layout }"></div>
        </div>

        <v-btn depressed color="#162CA2" @click="goToCheckoutPage" style="
            margin-top: 39px;
            padding: 25px;
            border-radius: 16px;
            width: 100%;
          ">
          <span class="text-capitalize font-weight-bold white--text"
            style="font-size: 16px; letter-spacing: 0 !important">DAFTAR SEKARANG</span>
        </v-btn>
        <v-btn outlined color="gray" style="
            margin-top: 10px;
            padding: 25px;
            border-radius: 16px;
            width: 100%;
          " @click="sendToWa()">
          <span class="text-capitalize font-weight-bold"
            style="font-size: 16px; letter-spacing: 0 !important">KONSULTASI SEKARANG</span>
        </v-btn>
      </div>
      <div class="container-parent" style="margin-top: -570px" ref="info_kelas">
        <div class="container-detail">
          <span class="addional">Info Kelas</span>
          <h5 class="title" style="font-family: 'Poppins', sans-serif !important">
            Deskripsi Program
          </h5>
          <p class="desc">{{ classDetail.description }}</p>
        </div>
      </div>
      <div class="container-parent mt-10" ref="timeline_belajar">
        <div class="container-detail">
          <span class="addional">Timeline Belajar</span>
          <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
            Jadwal Bootcamp
          </h5>
          <div class="timeline-bootcamp mt-5" v-for="(item, idx) in classDetail.schedules" :key="idx">
            <div style="padding: 20px">
              <p class="batch">Batch {{ item.batch_number }}</p>
              <p class="schedule">
                {{ formatDate(item.start_date) }} -
                {{ formatDate(item.end_date) }}
              </p>
            </div>
            <div class="line"></div>
            <div class="info-schadule">
              <div class="info-schadule-ctn">
                <GrowIc :width="25" fill="#7B7E8C" />
                <span>{{ classDetail.level }}</span>
              </div>
              <div class="info-schadule-ctn">
                <PeopleIc :width="25" fill="#7B7E8C" />
                <span>{{ item.total_audience }} Peserta</span>
              </div>
              <div class="info-schadule-ctn">
                <DateIc :width="25" fill="#7B7E8C" />
                <span>{{ item.session }} Sesi</span>
              </div>
              <div class="info-schadule-ctn">
                <MethodeIc :width="25" fill="#7B7E8C" />
                <span>{{
                classDetail.method ? toMethode(classDetail.method) : "Oflline/Online"
              }}</span>
              </div>
            </div>
            <img src="/img/icons/line-strip.png" alt="Link" style="width: 100%" />
            <div class="info-schadule mb-4" style="display: block">
              <div class="info-schadule-ctn">
                <ClockIc :width="25" fill="#7B7E8C" />
                <span style="padding-top: -10px">Jam Pelaksanaan</span>
              </div>
              <ul class="shadule">
                <li>
                  <div style="display: flex; gap: 20px; align-items: center">
                    <span class="font-poppins">{{ classDetail.days }}</span>
                    <span>:</span>
                    <span class="font-poppins">{{ classDetail.hours }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="alert-early" v-if="classDetail.is_discount">
              <span>Ada
                <span style="color: #162ca2; font-weight: bolder">Promo Early Bird</span>
                jika HiColers daftar bootcamp ini sekarang!</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-container" style="margin-bottom: 50px; background: #f1f6ff" ref="materi_silabus">
        <div class="container-detail" style="margin-top: 45px; padding: 40px 0px">
          <span class="addional">Materi Silabus</span>
          <h5 class="title mb-5" style="font-family: 'Poppins', sans-serif !important">
            Materi yang Akan HiColers Pelajari
          </h5>
          <!-- <p class="mb-4">Lorem ipsum dolor sit amet consectetur. Id nullam lorem interdum proin nisi. Amet ut ullamcorper eu vestibulum et cras lobortis. Convallis purus ut ultricies tempor euismod eget. Massa sed quis orci nunc tellus dolor id metus.</p> -->
          <div v-for="(item, idx) in classDetail.summary_syllabus" :key="idx" class="faq-box"
            @click="item.show = !item.show">
            <div class="fax-box-info">
              <p style="width: 90%">{{ item.name }}</p>
              <Arrow :down="item.show" fill="#36373f" />
            </div>
            <p v-if="item.show" v-html="item.value" class="faq-box-active"></p>
          </div>
        </div>
      </div>
      <div class="container-parent-grdn" style="padding-bottom: 50px">
        <div class="container-detail" ref="benefit">
          <span class="addional">Benefit</span>
          <h5 class="title mb-5" style="font-family: 'Poppins', sans-serif !important">
            Apa Saja yang Peserta Dapatkan
          </h5>
          <div class="benefits" v-for="(item, idx) in classDetail.benefits" :key="idx">
            <CheckIc :width="26" :height="26" outline fill="#575966" />
            <span style="font-family: 'Poppins', sans-serif">{{ item }}</span>
          </div>
        </div>
        <div class="container-detail mt-10" ref="tujuan_pelatihan">
          <span class="addional">Tujuan Pembelajaran</span>
          <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
            Goal pelatihan yang didapatkan
          </h5>
          <div class="learning-objective" ref="learning_objective_ctn" v-html="classDetail.learning_objective"></div>
        </div>
        <div class="container-detail" ref="taget_peserta">
          <span class="addional">Target Peserta</span>
          <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
            Kelas ini Cocok untuk
          </h5>
          <ul>
            <li v-for="(item, idx) in classDetail.target_audience" :key="idx">
              {{ item.level }}
            </li>
          </ul>
        </div>
      </div>
      <div class="container-parent" ref="kenapa_hicolleagues" style="margin: 50px 0">
        <div class="container-detail">
          <h5 class="title" style="font-family: 'Poppins', sans-serif !important">
            Kenapa Pilih Bootcamp HiColleagues?
          </h5>
          <div class="why-hicoll mt-5">
            <div class="card-hicol" v-for="(item, idx) in whyHiColl" :key="idx">
              <div>
                <img :src="item.img" width="70" alt="Ctn" />
              </div>
              <div>
                <h3>{{ item.title }}</h3>
                <p>{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-parent mt-10" ref="metode_belajar" style="margin: 80px 0">
        <div class="container-detail">
          <span class="addional">Metode Belajar</span>
          <h5 class="title" style="
              font-family: 'Poppins', sans-serif !important;
              line-height: 30px;
            ">
            Ikuti Bootcamp HiColleagues dengan Metode Belajar yang Konstruktif
          </h5>
          <div class="why-hicoll mt-8">
            <div v-for="(item, idx) in methodLearning" :key="idx">
              <div class="circle-yellow">
                <img :src="item.img" width="28" alt="Ctn" />
              </div>
              <h3 class="mt-3">{{ item.title }}</h3>
              <p style="color: #36373f">{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-parent mt-10" ref="biaya_pelatihan" style="margin: 50px 0">
        <div class="container-detail">
          <span class="addional">Biaya Pelatihan</span>
          <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
            Investasi Masa Depan yang Terjangkau
          </h5>
          <div class="box-price">
            <div class="early-bird">
              <span class="eb">{{
                classDetail.is_discount ? "Promo Early Bird" : "Harga Reguler"
              }}</span>
              <div>
                <h1 v-if="classDetail.is_discount">
                  {{ classDetail.discount_price | toCurrency }}
                </h1>
                <h1 v-else>{{ classDetail.price | toCurrency }}</h1>
                <div class="discount-hide" v-if="classDetail.is_discount">
                  <img src="/img/icons/line-discount.png" alt="line" class="line-disc" />
                  <span>{{ classDetail.price | toCurrency }}</span>
                </div>
              </div>
              <span class="sampai-tanggal" v-if="classDetail.is_discount">(Sampai {{
                formatDate(classDetail.end_discount_date) }})</span>
              <div class="event-due-date" v-if="expired.length && classDetail.is_discount">
                <div class="event-due-date-ctn">
                  <span>{{ expired[0] }}</span>
                  <span>Hari</span>
                </div>
                <span>:</span>
                <div class="event-due-date-ctn">
                  <span>{{ expired[1] }}</span>
                  <span>Jam</span>
                </div>
                <span>:</span>
                <div class="event-due-date-ctn">
                  <span>{{ expired[2] }}</span>
                  <span>Menit</span>
                </div>
                <span>:</span>
                <div class="event-due-date-ctn">
                  <span>{{ expired[3] }}</span>
                  <span>Detik</span>
                </div>
              </div>
            </div>
            <div style="margin: 20px auto; width: 70%">
              <div class="info-price-add" v-for="(item, idx) in priceAddInfo" :key="idx">
                <check-ic />
                <span>{{ item }}</span>
              </div>
              <div style="margin: 20px 0">
                <button-yellow name="DAFTAR SEKARANG" width="100%" @click="goToCheckoutPage" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-container" style="background: #f1f6ff; border-radius: 80px 80px 0 0"
        v-if="!classDetail.is_discount">
        <div class="container-detail" style="margin-top: 45px; padding: 40px 0px 0px 0px">
          <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
            Program Cicilan untuk Investasi Masa Depan
          </h5>
          <div class="cicilan" v-for="(item, idx) in methodePembayaran" :key="idx">
            <div class="cicilan-head">
              <p>{{ item.title }}</p>
              <span style="font-size: 14px">{{ item.type }}</span>
            </div>
            <div class="cicilan-body">
              <ul>
                <li class="cicilan-body-ctn" v-for="(item2, idx2) in item.list" :key="idx2" v-html="item2"></li>
              </ul>
            </div>
          </div>
          <div style="margin: auto; width: 50%; margin-top: 40px">
            <button-yellow name="KONSULTASI PROGRAM CICILAN" width="100%" height="50px" @click="goToCustomerService" />
          </div>
        </div>
      </div>
    </div>
    <div style="background: #f1f6ff; height: 80px"></div>
  </div>
</template>
<script>
import moment from "moment";

import goTo from "vuetify/lib/services/goto";
import ButtonYellow from "../../others/ButtonYellow.vue";
// import Button from "../../others/Button.vue";

import Arrow from "@/assets/icons/Arrow.vue";
import CheckIc from "@/assets/icons/Check.vue";
import ClockIc from "@/assets/icons/Clock.vue";
import MethodeIc from "@/assets/icons/Methode.vue";
import DateIc from "@/assets/icons/Date.vue";
import GrowIc from "@/assets/icons/Grow.vue";
import PeopleIc from "@/assets/icons/People.vue";

export default {
  name: "DetailClassBootcampDekstop",
  components: {
    // Button,
    ButtonYellow,
    Arrow,
    CheckIc,
    ClockIc,
    MethodeIc,
    DateIc,
    GrowIc,
    PeopleIc,
  },

  data() {
    return {
      whyHiColl: [
        {
          img: "/img/bootcamp/ic_intensive_program.png",
          title: "Intensive Program",
          desc: "Durasi program 2-3 bulan yang mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri",
        },
        {
          img: "/img/bootcamp/ic_competent_trainer.png",
          title: "Competent Trainer",
          desc: "Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan",
        },
        {
          img: "/img/bootcamp/ic_sertifikat_completion.png",
          title: "Learning Management System",
          desc: "Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat",
        },
        {
          img: "/img/bootcamp/ic_sertifikat_kompetensi.png",
          title: "E-Certificate",
          desc: "Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir",
        },
        {
          img: "/img/bootcamp/ic_job_recommentation.png",
          title: "Job Connector",
          desc: "Update informasi dan perekomendasian peluang kerja di industri nyata bagi alumni peserta Bootcamp",
        },
        {
          img: "/img/bootcamp/ic_installment.png",
          title: "Installment Option Available",
          desc: "Biaya pelatihan yang terjangkau hingga program pembayaran dengan sistem cicilan untuk mendukung akselerasi skill peserta",
        },
      ],
      methodLearning: [
        {
          img: "/img/bootcamp/chat.png",
          title: "Join Community Group",
          desc: "Tersedia grup Telegram untuk memfasilitasi kolaborasi, konsultasi, dan pertukaran informasi antara peserta, trainer, dan admin grup.",
        },
        {
          img: "/img/bootcamp/zoom.png",
          title: "Live Class via Zoom",
          desc: "Peserta akan mengikuti sesi pembelajaran secara daring menggunakan Zoom.",
        },
        {
          img: "/img/bootcamp/lms.png",
          title: "Access to LMS",
          desc: "Peserta mendapatkan akses LMS untuk mempermudah proses pembelajaran selama bootcamp.",
        },
        {
          img: "/img/bootcamp/partical.png",
          title: "Practical Learning",
          desc: "Peserta belajar melalui materi dan langsung menerapkannya dalam pengerjaan proyek yang relevan.",
        },
        {
          img: "/img/bootcamp/sharing.png",
          title: "Sharing Session",
          desc: "Peserta dapat berdiskusi dan mendengarkan pengalaman langsung dari trainer ketika sesi pembelajaran berlangsung.",
        },
        {
          img: "/img/bootcamp/coumunication.png",
          title: "Live Consultation",
          desc: "Peserta berkesempatan mendapatkan bimbingan dan mentoring pribadi langsung dari trainer.",
        },
        {
          img: "/img/bootcamp/project.png",
          title: "Final Project & Exam",
          desc: "Pada akhir pembelajaran, peserta wajib untuk mengerjakan proyek akhir dan ujian akhir untuk mengukur pemahaman peserta.",
        },
      ],
      menuPublicClass: [
        { name: "Info Kelas", id: 1, position: 0, layout: "info-kelas" },
        {
          name: "Timeline Belajar",
          id: 2,
          position: 0,
          layout: "timeline-belajar",
        },
        {
          name: "Materi Silabus",
          id: 3,
          position: 0,
          layout: "materi-silabus",
        },
        { name: "Benefit", id: 4, position: 0, layout: "benefit" },
        {
          name: "Tujuan Pelatihan",
          id: 5,
          position: 0,
          layout: "tujuan-pelatihan",
        },
        {
          name: "Target Peserta",
          id: 6,
          position: 0,
          layout: "tagret-peserta",
        },
        {
          name: "Kenapa HiColleagues",
          id: 7,
          position: 0,
          layout: "kenapa-hicoleagues",
        },
        {
          name: "Metode Belajar",
          id: 8,
          position: 0,
          layout: "methode-belajar",
        },
        {
          name: "Biaya Pelatihan",
          id: 9,
          position: 0,
          layout: "biaya-pelatihan",
        },
      ],
      methodePembayaran: [
        {
          title: "Pembayaran Pertama",
          type: "(Saat Pendaftaran)",
          list: [
            "<span>Ketika calon peserta mendaftar untuk bootcamp, calon peserta akan diminta untuk</span> membayar pembayaran pertama.",
            "<span>Besaran</span> pembayaran pertama adalah 1/3 dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>",
            "<span>Calon peserta akan dianggap</span> resmi terdaftar setelah pembayaran pertama diterima",
          ],
        },
        {
          title: "Pembayaran Kedua",
          type: "(Tengah Training / Senin, 13 November 2023)",
          list: [
            "Pembayaran kedua akan jatuh tempo pada tengah periode pelatihan, <span>yaitu pada tanggal yang telah ditentukan dalam perjanjian pendaftaran.</span>",
            "<span>Besaran</span> pembayaran kedua juga adalah 1/3 dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>",
            "<span>Peserta akan</span> diingatkan sebelum jatuh tempo <span>pembayaran kedua</span>",
          ],
        },
        {
          title: "Pembayaran Ketiga",
          type: "(Sebelum Penerimaan Sertifikat)",
          list: [
            "<span>Pembayaran ketiga akan dilakukan setelah calon peserta</span> menyelesaikan seluruh pelatihan dan memenuhi semua persyaratan <span>yang diperlukan.</span>",
            "Pembayaran ketiga adalah sisa dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>",
            "<span>Peserta akan diingatkan</span> sebelum jatuh tempo <span>pembayaran ketiga.</span>",
            "<span>Setelah pembayaran ketiga diterima, peserta akan diberikan</span> sertifikat sebagai bukti kelulusan.",
          ],
        },
      ],
      priceAddInfo: [
        "Tipe kelas Public",
        "Kapasitas max 10 peserta",
        "Jadwal kelas telah ditentukan",
        "Diajarkan langsung oleh mentor",
        "Gratis Survival Kit",
        "Sertifikat Kelulusan",
      ],
      classDetail: {
        schedules: [],
      },
      expired: [],
      layout: "info-kelas",
    };
  },
  methods: {
    mappingHeightOfElement() {
      let point = this.$refs.ctn_head.clientHeight;
      this.menuPublicClass[0].position = point;
      point += this.$refs.info_kelas.clientHeight;
      this.menuPublicClass[1].position = point;
      point += this.$refs.timeline_belajar.clientHeight;
      this.menuPublicClass[2].position = point;
      point += this.$refs.materi_silabus.clientHeight;
      this.menuPublicClass[3].position = point;
      point += this.$refs.benefit.clientHeight;
      this.menuPublicClass[4].position = point;
      point += this.$refs.tujuan_pelatihan.clientHeight;
      this.menuPublicClass[5].position = point;
      point += this.$refs.taget_peserta.clientHeight + 200;
      this.menuPublicClass[6].position = point;
      point += this.$refs.kenapa_hicolleagues.clientHeight + 220;
      this.menuPublicClass[7].position = point;
      point += this.$refs.metode_belajar.clientHeight;
      this.menuPublicClass[8].position = point;
      point += this.$refs.biaya_pelatihan.clientHeight;
      this.menuPublicClass[9].position = point;
    },
    clickActiveMenu(e, p) {
      this.layout = e;
      console.log(p);
      goTo(Number(p + 10));
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    goToCheckoutPage() {
      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.is_discount
          ? this.classDetail.discount_price
          : this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: "BOOTCAMP_CLASS",
        schedule: this.classDetail.schedules,
        days: this.classDetail.days,
        methode: this.classDetail.method,
        category_id: this.classDetail.category_id,
      });
      localStorage.clear();
      localStorage.setItem("checkout", req);
      window.location.href = "/checkout";
    },
    goToCustomerService() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya";
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        const end = new Date(this.classDetail.end_discount_date);
        const now = new Date();
        const distance = end - now;
        if (distance < 0) {
          clearInterval(timer);
          this.expired = [];
          return;
        }
        days = Math.floor(distance / _day);
        hours = Math.floor((distance % _day) / _hour);
        minutes = Math.floor((distance % _hour) / _minute);
        seconds = Math.floor((distance % _minute) / _second);
        this.expired = [days, hours, minutes, seconds];
      };
      timer = setInterval(showRemaining, 1000);
    },
    async getDetailClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class-detail/" + this.$route.params.slug_name)
        .then((response) => {
          const data = response.data.data.class_detail;
          this.classDetail = {
            ...data,
            summary_syllabus: data.summary_syllabus
              ? data.summary_syllabus.map((item) => ({
                ...item,
                show: true,
              }))
              : [],
          };
          this.countDownTimer();
          setTimeout(() => {
            this.setToPoppinsListLearningObjective();
            this.mappingHeightOfElement();
          }, 1000);
        })
        .catch((err) => console.error(err));
      // .finally(() => this.spin_load.val = false)
    },
    handleScroll() {
      this.menuPublicClass.forEach((item) => {
        if (item.position <= window.scrollY) {
          this.layout = item.layout;
        }
      });
    },
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    setToPoppinsListLearningObjective() {
      const elementLi =
        this.$refs.learning_objective_ctn.getElementsByTagName("li");
      for (let i = 0; i < elementLi.length; i++) {
        elementLi[i].style.fontFamily = "Poppins";
      }
    },
    toMethode(value) {
      if (!value) return
      if (value.toLowerCase() === "online") {
        return "Daring (via Zoom)"
      } else if (value.toLowerCase() === "offline") {
        return "Luring"
      } else {
        return value
      }
    }
  },
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.getDetailClass();
    window.scrollTo(0, 0);
  },
};
</script>

<style src="./css/detailClassBootcamp.css" scoped></style>