<template>
    <div>
        <MobileKampusMerdeka v-if="isMobile" />
        <DesktopKampusMerdeka v-if="!isMobile" />
    </div>
</template>

<script>
import DesktopKampusMerdeka from '@/components/kampus-merdeka/desktop/KampusMerdeka'
import MobileKampusMerdeka from '@/components/kampus-merdeka/mobile/KampusMerdeka'

export default {
  components: {
    MobileKampusMerdeka,
    DesktopKampusMerdeka,
  },
  data(){
      return{
          isMobile: false
      }
  },
  methods: {
      handleView() {
          this.isMobile = window.innerWidth <= 990
      }
  },
  mounted() {
    this.$utils.scrollTop()
  },
  beforeMount() {
      this.handleView()
  }
}
</script>