<template>
  <div>
    <DesktopCorporateTraining v-if="!isMobile" />
    <MobileCorporateTraining v-if="isMobile" />
  </div>
</template>

<script>
import DesktopCorporateTraining from "@/components/corporate/training/desktop/CorporateTraining";
import MobileCorporateTraining from "@/components/corporate/training/mobile/CorporateTraining";
export default {
  components: {
    DesktopCorporateTraining,
    MobileCorporateTraining,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  metaInfo() {
      const thisPath = window.location.href
      return {
        title: "Corporate Training",
        htmlAttrs: { lang: "id",amp: true},
        meta: [
          { charset: "utf-8" },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name:"keywords", content:"Corporate Training,HiColleagues,Corporate Training HiColleagues"},
          { name: "description", content: "Program pilihan untuk meningkatkan skill karyawan Anda…"},
        ],
        link: [
          {
            rel: 'canonical',
            href: thisPath
          }
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Corporate Training",
              "url": thisPath,
              "potentialAction": {
                "@type": "SearchAction",
                "target": thisPath
              }
            }
          }
        ]
      };
    },
  methods: {
    handleView() {
      this.isMobile = screen.width <= 990;
    },
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView();
  }
};
</script>
