<template>
    <div>
      <DesktopCareer v-if="!isMobile"/>
      <MobileCareer v-if="isMobile"/>
    </div>
  </template>
  <script>
  import DesktopCareer from "@/components/career/main/desktop/Career.vue";
  import MobileCareer from  "@/components/career/main/mobile/Career.vue"
  
  export default {
    components: {
      DesktopCareer,
      MobileCareer,
    },
    data() {
      return {
        isMobile: false,
      };
    },
    methods: {
      handleView() {
        this.isMobile = screen.width <= 990;
      },
    },
    mounted() {
      this.$gtag.pageview(this.$route);
      this.$utils.scrollTop()
    },
    beforeMount() {
      this.handleView();
    }
  };
  </script>
  