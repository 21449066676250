<template>
  <div class="body">
    <div class="hero" style="margin-top: 63px">
      <div class="content">
        <h1>
          Tingkatkan Performa Karir <br />
          melalui Program Akselerasi Digital
        </h1>
        <p>
          Perluas potensi diri dengan pelatihan konstruktif dan kredibel sebagai
          solusi penunjang profesionalisme karir bersama HiColleagues
          <b>#CiptakanMasaDepan</b>
        </p>
        <div class="btn-cta">
          <button class="now" @click="scrollToClass">LIHAT KELAS</button>
          <button class="call" @click="sendToWa">HUBUNGI KAMI</button>
        </div>
      </div>
      <div class="card-img">
        <img src="/img/corporate/acceleration.png" width="520" height="" alt="" />
      </div>
    </div>
    <div class="container" style="text-align: center; margin-top: 40px">
      <h3 class="sub-title-span">
        HiColleagues Pilihan Tepat Akselerasi Skill Digital
      </h3>
      <div class="mb-10 mt-5 card-bootcamp">
        <div v-for="n in benefits" :key="n.id" class="content-card mr-2 ml-2">
          <div class="left-span">
            <img :src="n.image" width="100" height="100" />
          </div>
          <div>
            <div class="title-2" style="text-align: left">{{ n.title }}</div>
            <div class="desc mt-2" style="text-align: left">{{ n.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-of-acceleration">
      <div class="container">
        <h1 class="text-center my-1">Program Akselerasi Digital</h1>
        <p class="text-center my-1">
          Rekomendasi program pelatihan untuk akselerasi skill talenta digital
          dan peningkatan performa karir cemerlang
        </p>
        <div class="card" style="padding-bottom: 60px">
          <div class="items" v-for="(item, idx) in accelerations" :key="idx" @click="
            $router.push(`/corporate/acceleration/detail/${item.slug_name}`)
            ">
            <div style="height: 200px">
              <img :src="item.image_url" alt="" style="width: 100%; height: 100%; border-radius: 20px 20px 0 0" />
              <div class="label">
                <img :src="item.collaboration_feed" alt="" style="width: 80%" />
              </div>
            </div>
            <div class="content">
              <h2>{{ item.name }}</h2>
              <div class="category">
                <div style="background-color: #f1f6ff" v-if="item.type">
                  <p>{{ item.type }}</p>
                </div>
                <div style="background-color: #faeceb" v-if="item.class_type">
                  <p>{{ item.class_type }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="item.method">
                  <p>{{ toMethode(item.method) }}</p>
                </div>
              </div>
              <p class="description-class">{{ item.description }}</p>
              <div style="height: 60px">
                <p style="color: #b80f0a; font-size: 16px; margin: 0" v-if="item.is_discount">
                  <del>{{ item.discount | toCurrency }}</del>
                </p>
                <h3 style="color: #162ca2; margin: 0" v-if="!item.is_discount">
                  {{ item.price | toCurrency }}
                </h3>
                <h3 style="color: #162ca2; margin: 0" v-else>
                  {{ toDiscount(item) }}
                </h3>
              </div>
            </div>
            <div class="due-date">
              <DateIc2 />
              <span style="font-size: 15px">{{
            item.registration_close_date | formatDate
          }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Testimony title="Apa Kata Alumni Kami" :testimonies="testimonies" />
    <div class="faq" style="margin-top: 60px;">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header" style="margin-bottom: 20px;">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: -10px;">
            <v-expansion-panels flat class="faq-card" style="padding: 20px; background-color: #F1F6FF;">
              <v-expansion-panel v-for="(item, index) in questions" :key="index"
                style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)">
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow />
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- <div class="btn-stoke-blue" @click="$router.push('/faq')">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";
import DateIc2 from "@/assets/icons/DateIc2.vue";
import Testimony from "../../Testimony.vue";

export default {
  components: {
    Arrow,
    DateIc2,
    Testimony,
  },
  data() {
    return {
      benefits: [
        {
          title: "Intensive Program",
          desc: "Durasi program relatif singkat & mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri",
          image: "/img/bootcamp/ic_intensive_program.png",
        },
        {
          title: "Highly  Relevant Case",
          desc: "Pelatihan berbasis 80% praktik & project based learning terhadap kasus yang sangat relevan dalam perkembangan dunia industri digital terkini",
          image: "/img/bootcamp/ic_sertifikat_completion.png",
        },
        {
          title: "Competent Trainer",
          desc: "Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan",
          image: "/img/bootcamp/ic_competent_trainer.png",
        },
        {
          title: "Learning Management System",
          desc: "Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat",
          image: "/img/bootcamp/ic_sertifikat_completion.png",
        },
        {
          title: "E-Certificate",
          desc: "Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir",
          image: "/img/bootcamp/ic_sertifikat_kompetensi.png",
        },
        {
          title: "Perluas Jangkauan Potensi Karir",
          desc: "Kredibilitas dan sertifikasi yang diperoleh dari program akselerasi dapat membuat peserta menjadi kandidat yang lebih menarik bagi perusahaan-perusahaan ternama.",
          image: "/img/bootcamp/ic_installment.png",
        },
      ],
      accelerations: [],
      showDesc: [], // array to track visibility of description
      questions: [],
      testimonies: [],
      current_page: 1,
      total_page: 1,
      perPage: 3,
    };
  },
  methods: {
    toggleAnswer(index) {
      // Toggle the visibility of description for the selected item
      this.$set(this.showDesc, index, !this.showDesc[index]);
    },
    scrollToClass() {
      window.scrollTo(0, 1000);
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get(
          "/v1/testimony?category=ACCELERATION&page=1&per_page=9"
        );
        this.testimonies = resp.data.data.testimonies;
        while (this.testimonies.length % 3 !== 0) {
          this.testimonies.push("");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getMainClass() {
      try {
        const resp = await this.$http.get("/v1/class/category/6");
        this.accelerations = resp.data.data.classes;
      } catch (err) {
        console.log(err);
      }
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active;
      this.questions.forEach((e) => {
        e.active = false;
      });
      this.questions[idx].active = !valDef;
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=ACCELERATION&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    toMethode(value) {
      if (!value) return;
      if (value.toLowerCase() === "online") {
        return "Daring";
      } else if (value.toLowerCase() === "offline") {
        return "Luring";
      } else {
        return value;
      }
    },
    toDiscount(item) {
      const total_price = item.price - item.discount;
      if (total_price <= 0) {
        return "FREE";
      } else {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return formatter.format(total_price);
      }
    },
  },
  mounted() {
    this.getTestimoni();
    this.getFaq();
    this.getMainClass();
  },
};
</script>

<style src="./accelerationProgram.css" scoped></style>
