<template>
    <div class="faq-section">
      <h2 class="sub-title-span">Pertanyaan Seputar Kelas Prakerja</h2>
      <div class="container mt-10">
        <div
          v-for="(item, idx) in faq"
          :key="idx"
          @click="openFaq(idx)"
          class="faq-box"
        >
          <div class="fax-box-info">
            <p style="width: 90%">{{ item.title }}</p>
            <Arrow :down="item.active" />
          </div>
          <p v-if="item.active" class="faq-box-active" v-html="item.description"></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Arrow from "../../assets/icons/Arrow.vue";
  
  export default {
    data() {
      return {
        faq: [],
      };
    },
    components: {
      Arrow,
    },
    methods: {
      openFaq(idx) {
        const valDef = this.faq[idx].active;
        this.faq.forEach((e) => {
          e.active = false;
        });
        this.faq[idx].active = !valDef;
      },
      async getFaq() {
        this.faq = []
        try {
          const resp = await this.$http.get("/v1/faq?category=PRAKERJA")
          this.faq = resp.data.data.faq.map((item) => ({
            description: item.answer[0],
            title: item.question,
            active: false
          }))
        } catch (err) {
          console.log(err);
        }
      }
    },
    mounted() {
      this.getFaq()
    }
  };
  </script>
  
  <style src="./css/faqPrakerja.css" scoped></style>
  