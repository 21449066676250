<template>
    <div style="margin-top: 63px">
        <div class="hero">
            <div class="hero-ctn">
                <h1>Portfolio</h1>
                <p>Berbagai proyek kemitraan dalam transformasi digital berkelanjutan yang telah berhasil HiColleagues
                    selenggarakan</p>
            </div>

        </div>
        <div style="background-color: #f5f6fa;">
            <div class="container">
                <div class="d-flex container justify-center" style="margin-top: 41px">
                    <div class="inactive" @click="getPortfolio(data.value)" :class="{ active: category === data.value }"
                        v-for="(data, idx) in categoryList" :key="idx">
                        <span>{{ data.name }}</span>
                    </div>
                </div>
                <div class="card" style="padding-bottom: 60px">
                    <div class="items" v-for="(item, idx) in projectPortfolio" :key="idx"
                        @click="$router.push(`/portfolio/${item.id}`)">
                        <div style="height: 240px">
                            <img :src="item.image_url" alt=""
                                style="width: 100%; height: 100%; border-radius: 20px 20px 0 0" />
                        </div>
                        <div class="content">
                            <div style="height: 70px;">
                                <h2>{{ item.program }}</h2>
                            </div>
                            <p class="description-class">{{ item.description }}</p>

                        </div>
                    </div>
                </div>
                <EmptyData v-if="!projectPortfolio || !projectPortfolio.length"
                    message="Belum ada portofolio saat ini" />
                <div class="pagination" v-if="total > 1">
                    <span class="page-link" @click="page('prev')">Sebelumnya</span>
                    <span :class="`page-link ${currentPage === idx ? 'active' : ''}`" v-for="idx in total" :key="idx">{{
                            idx }}</span>

                    <span class="page-link" @click="page('next')">Selanjutnya</span>
                </div>
            </div>
        </div>
        <v-card elevation="0" class="call-center-container">
            <v-row style="padding: 40px">
                <v-col cols="7">
                    <div class="call-center-title">
                        Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam Transformasi Digital
                        Berkelanjutan
                    </div>
                    <div class="call-center-desc">
                        Dapatkan berbagai informasi yang kamu butuhkan melalui layanan konsultasi 24/7 Customer Service
                        HiColleagues
                    </div>
                </v-col>
                <v-col cols="1" />
                <v-col cols="4" class="call-center-button" @click="sendWa">
                    <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import EmptyData from '../../others/EmptyData.vue';

export default {
    name: "DesktopAlumniStories",
    components: {
        EmptyData
    },
    data() {
        return {
            projectPortfolio: [],
            currentPage: 1,
            total: 1,
            category: "COLLABORATION",
            categoryList: [
                {
                    name: "Corporate Collaboration",
                    value: "COLLABORATION"
                },
                {
                    name: "Corporate Training",
                    value: "TRAINING"
                },
                {
                    name: "Technology Service",
                    value: "SERVICES"
                },
                {
                    name: "Kampus Merdeka",
                    value: "KAMPUS_MERDEKA"
                },
            ]
        }
    },
    methods: {
        sendWa() {
            window.open("https://wa.me/6281234567890", "_blank");
        },
        page(act) {
            if (act === 'prev') {
                if (this.currentPage <= 1) return
                this.currentPage--
            } else {
                if (this.currentPage >= this.total) return
                this.currentPage++
            }
            // this.getTestimoni()
        },
        async getPortfolio(val) {
            if (val) this.category = val
            try {
                const resp = await this.$http.get(`/v1/project-portfolio?category=${this.category}&page=${this.currentPage}&per_page=9`)
                this.projectPortfolio = resp.data.data.project_portfolios
                if (resp.data.data.total) {
                    this.total = parseInt(resp.data.data.total / 9) + 1;
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
    mounted() {
        this.getPortfolio()
    }
}
</script>

<style scoped src="./portfolio.css"></style>