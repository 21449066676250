<template>
    <div>
        <div class="container-size-blog">
            <div class="navigation">
                <span @click="$router.push('/portfolio')" class="nav-active font-poppins">Home</span>
                <Arrow :width="20" direct="left" />
                <span class="font-poppins">{{ portfolio.program.length > 60 ? portfolio.program.slice(0, 60) + "..." :
                    portfolio.program }}</span>
            </div>
            <img loading="lazy" :src="portfolio.image_url" class="img-main-dekstop" />
            <h2 class="mt-5 mb-0">{{ portfolio.program }}</h2>
            <p style="margin: 0;">Kolaborasi Bersama : Tim HiColleagues dengan {{ portfolio.title }}</p>
            <br>
            <div class="text-justify mt-5" v-html="portfolio.body"></div>
            <div v-if="portfolio.preview">
                <h2>Preview</h2>
                <img loading="lazy" :src="portfolio.preview" class="img-main-dekstop"
                    @click="toRedirect(portfolio.link_web)" />
            </div>
            <div style="height: 30px"></div>
        </div>
        <v-card elevation="0" class="call-center-container">
            <v-row style="padding: 40px">
                <v-col cols="7">
                    <div class="call-center-title">
                        Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam Transformasi Digital
                        Berkelanjutan
                    </div>
                    <div class="call-center-desc">
                        Dapatkan berbagai informasi yang kamu butuhkan melalui layanan konsultasi 24/7 Customer Service
                        HiColleagues
                    </div>
                </v-col>
                <v-col cols="1" />
                <v-col cols="4" class="call-center-button" @click="sendWa">
                    <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";

export default {
    name: "DekstopDetailAlumniStories",
    components: {
        Arrow,
    },
    data() {
        return {
            portfolio: {
                image_url: "",
                title: "",
                label: "",
                body: "",
                program: "",
                description: "",
                category: "",
                preview: "",
                link_web: ""
            }
        }
    },
    methods: {
        sendWa() {
            window.open("https://wa.me/6281234567890", "_blank");
        },
        toRedirect(to) {
            window.open(to)
        },
        async getPortfolioDetail() {
            try {
                const resp = await this.$http.get(`/v1/project-portfolio/${this.$route.params.id}`)
                this.portfolio = resp.data.data.project_portfolio
            } catch (err) {
                console.log(err);
            }
        }
    },
    mounted() {
        this.getPortfolioDetail()
    }
}
</script>

<style scoped src="./detail.css"></style>