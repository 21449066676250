<template>
  <div style="overflow-x: hidden">
    <Loading v-if="isLoading" />
    <Popup
      message="Yay, Kode Voucher dan Kode Redeem telah diterapkan. selanjutnya pilih kelas pakerja yang kamu inginkan"
      :isError="false" v-if="isPopup" @close="() => (isPopup = false)" />
    <Modal v-if="isModal" @close="() => (isModal = false)" width="95%">
      <div style="text-align: center">
        <h2>Konfirmasi Kode Voucher</h2>
        <p class="voucher-desc">
          Masukkan Kode Voucher Kelas Prakerja yang telah kamu dapatkan dari
          Mitra Platform Digital kami di sini
        </p>
        <div class="input-box">
          <p>Kode Voucher Kelas Prakerja</p>
          <v-text-field :disabled="confirmVoucher" outlined v-model="codeVoucher" class="form-input"
            placeholder="Masukkan Kode Voucher" dense clearable></v-text-field>
          <p>Kode Redeem Prakerja</p>
          <v-text-field :disabled="confirmVoucher" outlined v-model="codeRedeem" class="form-input"
            placeholder="Masukkan Kode Voucher" dense clearable></v-text-field>
        </div>
        <div class="alert-notif" v-if="isError == true || isError == false">
          <span style="color: #b80f0a" v-if="isError == true">Kode Voucher tidak valid!</span>
          <span style="color: #01a368" v-if="isError == false">Kode Voucher valid!</span>
        </div>
        <div class="button-confirm">
          <Button name="Konfirmasi" :disabled="!codeVoucher || !codeRedeem || confirmVoucher" height="45px" width="100%"
            @click="onCheckVaidVoucher" />
        </div>
      </div>
    </Modal>
    <div class="banner">
      <h2 class="title-banner">
        Wujudkan Mimpimu tanpa Mikirin Biaya dengan Kartu Prakerja
      </h2>
      <p class="sub-title-banner">
        Kembangkan kompetensi kerja & kewirausahaan dengan ikut Kelas Prakerja
        HiColleagues sebagai mitra resmi Lembaga Pelatihan Kartu Prakerja
        <b>#SiapDariSekarang</b>
      </p>
    </div>
    <div class="bg-white-radius" style="margin-bottom: 20px">
      <!-- <ExchangeVoucher style="margin-bottom:80px;"/> -->
    </div>
    <div class="container text-center" style="
        margin-top: -50px;
        background: linear-gradient(
          180deg,
          rgba(241, 246, 255, 0) 0%,
          #f1f6ff 100%
        );
        height: 700px;
        position: relative;
      ">
      <!-- <div class="voucher-box">
        <p class="voucher-box-desc">
          Sudah beli kelas Prakerja di Mitra Digital Platform HiColleagues?
        </p>
        <div
          class="d-flex bg-yellow justify-center"
          @click="openModalTukarVoucher"
        >
          <img src="/img/other/ic_ticket.png" width="28" height="22" />
          <span class="exhange-voucher">TUKAR KODE VOUCHER</span>
        </div>
      </div> -->
      <h3 class="sub-title-span">Yuk Gabung Kelas Prakerja di HiColleagues</h3>
      <span class="font-poppins" style="font-size: 13px">Rekomendasi kelas yang cocok untuk tingkatkan kompentensi kerja
        &
        kewirausahaan HiColers!</span>
      <div class="scrolling-wrapper">
        <div v-for="n in listOfPrakerja" :key="n.id" class="mt-5 card">
          <v-card class="black--text prakerja-card mr-5" style="height: 360px">
            <div @click="
      $router.push(
        `/class/prakerja/${n.slug_name}?tipe_kelas=prakerja`
      )
      ">
              <img :src="n.image_url" width="310px" height="200px" style="
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                " />
            </div>
            <div class="class-name" style="text-align: left; height: 80px; margin-top: 14px">
              <p @click="
      $router.push(
        `/class/prakerja/${n.slug_name}?tipe_kelas=prakerja`
      )
      ">
                {{ n.name }}
              </p>
              <Button name="DAFTAR KELAS" bold @click="() => toCheckout(n)" width="100%" />
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <div class="blue-section text-justify">
      <h1 class="text-center" style="font-size: 20px">
        Yuk Gabung Kelas Prakerja di HiColleagues
      </h1>
      <img src="/img/prakerja/Prakerja.png" alt="Prakerja Banner" class="mt-4"
        style="border-radius: 14px; width: 100%" />
      <div class="prakerja-ctn">
        <p>
          Program Kartu Prakerja adalah program pengembangan kompetensi kerja
          dan kewirausahaan yang ditujukan untuk pencari kerja, pekerja/buruh
          yang terkena pemutusan hubungan kerja, dan/atau pekerja/buruh yang
          membutuhkan peningkatan kompetensi, termasuk pelaku usaha mikro dan
          kecil. Kami percaya bahwa masyarakat Indonesia sesungguhnya ingin
          selalu meningkatkan kemampuannya. Program ini didesain sebagai sebuah
          produk dan dikemas sedemikian rupa agar memberikan nilai bagi pengguna
          sekaligus memberikan nilai bagi sektor swasta.
        </p>
        <p>
          Jalan digital melalui marketplace dipilih untuk memudahkan pengguna
          mencari, membandingkan, memilih dan memberi evaluasi. Hanya dengan
          cara ini, produk bisa terus diperbaiki, tumbuh dan relevan.
          Menggandeng pelaku usaha swasta, program ini adalah wujud kerjasama
          pemerintah dan swasta dalam melayani masyarakat dengan semangat gotong
          royong demi SDM unggul, Indonesia maju.
        </p>
        <h4 style="font-size: 20px; margin-top: 30px">
          Syarat Mengikuti Kartu Prakerja
        </h4>
      </div>
      <div v-for="(item, idx) in requrementsPrakerjaProgram" :key="idx"
        style="display: flex; margin-top: 6px; gap: 5px">
        <img src="/img/icons/check.png" style="width: 25px; height: 25px" alt="Check" />
        <p v-html="item" style="font-size: 16px"></p>
      </div>
      <h1 class="sub-title-span mt-5" style="text-align: left">
        ROADMAP KELAS PRAKERJA
      </h1>
      <div class="roadmap-line">
        <img src="/img/prakerja/line-vertikal.png" alt="line prakerja" />
        <div class="raoadmap-content">
          <div class="roadmap-info" v-for="(item, idx) in roadmap" :key="idx">
            <div class="rounded-blue-out">
              <div class="rounded-blue">
                <span>{{ idx + 1 }}</span>
              </div>
            </div>
            <div style="text-align: left">
              <h6 style="font-size: 16px">
                {{ item.title }}
              </h6>
              <span class="font-poppins" style="margin-top: 6px; font-size: 14px">{{ item.desc }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-blue button-other" @click="goTo('https://www.prakerja.go.id/')">
        <span class="font-poppins">PELAJARI LEBIH LANJUT</span>
        <Arrow direct="left" />
      </div>
    </div>

    <div class="container" style="margin: 50px auto">
      <h2 class="sub-title-span text-center">
        Kenapa Harus Beli Kelas HiColleagues?
      </h2>
      <div v-for="n in benefits" :key="n.id" class="content-card mr-2 ml-2">
        <div class="left-span">
          <img :src="n.image" width="70" height="70" style="border-radius: 50%" />
        </div>
        <div>
          <h3 class="title-2">{{ n.title }}</h3>
          <p class="desc mt-2">{{ n.desc }}</p>
        </div>
      </div>
    </div>
    <h3 class="sub-title-span text-center">Cerita Alumni</h3>
    <Alumni style="padding: 0 12px" />
    <FaqPrakerja class="container" style="margin-top: 30px" />
  </div>
</template>

<script>
import Popup from "@/components/others/Popup.vue";
import Modal from "@/components/others/Modal.vue";
import Button from "@/components/others/Button.vue";
import goTo from "vuetify/lib/services/goto";
import FaqPrakerja from "@/components/prakerja/FaqPrakerja";
import Alumni from "@/components/prakerja/Alumni";
import Arrow from "@/assets/icons/Arrow.vue";
import Loading from "../../ilustration/Loading.vue";

export default {
  components: {
    FaqPrakerja,
    Alumni,
    Modal,
    Popup,
    Button,
    Arrow,
    Loading,
  },
  data() {
    return {
      roadmap: [
        {
          title: "Pendaftaran Kartu Prakerja",
          desc: "Lakukan pendaftaran peserta melalui Website Kartu Prakerja.",
          id: 1,
        },
        {
          title: "Seleksi Kartu Prakerja",
          desc: "Ikuti Tes Motivasi & Kemampuan Dasar untuk mendapatkan Unique ID Kartu Prakerja per Batch-nya.",
          id: 2,
        },
        {
          title: "Pilih Lembaga Pelatihan HiColleagues",
          desc: "Jelajahi Kelas Prakerja di website HiColleagues. Atau beli Kelas Prakerja yang kamu minati di Mitra Kartu Prakerja HiColleagues dan dapatkan Kode Voucher kelas di setiap pembeliannya.",
          id: 3,
        },
        {
          title: "Tukar Kode Voucher",
          desc: "Tukarkan Kode Voucher di HiColleagues. Dapatkan Email Konfirmasi untuk Login dalam LMS dan ikuti Kelas Pelatihan Webinar via Zoom.",
          id: 4,
        },
        {
          title: "Ikuti Pelatihan",
          desc: "Ikuti dan selesaikan Kelas Pelatihan untuk mendapatkan sertifikat sebagai bukti bahwa kamu telah menyelesaikan kelas yang kamu minati dengan baik.",
          id: 5,
        },
        {
          title: "Berikan Ulasan & Rating",
          desc: "Berikan Ulasan & Rating pada Kelas Pelatihan yang telah diikuti di form HiColleagues & platform Mitra Kartu Prakerja.",
          id: 6,
        },
        {
          title: "Isi Survey & Dapatkan Insentif",
          desc: "Isi survey yang tersedia di dashboard Kartu Prakerja dan dapatkan insentif dari pemerintah.",
          id: 7,
        },
      ],
      whyHicolleagues: [
        {
          image: "/img/prakerja/ic_mentor_berpengalaman.png",
          title: "Instruktur Berpengalaman",
          desc: "Pelatihan akan dibimbing oleh instruktur yang berpengalaman di bidangnya.",
        },
        {
          image: "/img/prakerja/ic_lms.png",
          title: "Menggunakan LMS",
          desc: "LMS (Learning Management System) upaya mendukung kegiatan Kelas Prakerja berjalan optimal.",
        },
        {
          image: "/img/prakerja/ic_materi_relevan.png",
          title: "Materi Relevan",
          desc: "Materi yang dipelajari dibutuhkan industri saat ini & potensional untuk masa depan.",
        },
      ],
      partnerships: [
        {
          image: "/img/partner/tokopedia.png",
          link: "https://www.tokopedia.com/kartu-prakerja/partner/hicolleagues",
        },
        {
          image: "/img/partner/bukalapak.png",
          link: "https://www.bukalapak.com/kartu-prakerja/pencarian?source=dope&source=navbar&from=omnisearch&from_keyword_history=false&search_source=omnisearch_virtual_products&keyword=hicolleagues",
        },
        {
          image: "/img/partner/pintaria.png",
          link: "https://pintar.co/kartuprakerja/HiColleagues",
        },
        {
          image: "/img/partner/kariermu.png",
          link: "https://www.karier.mu/program?search=hicolleagues",
        },
        {
          image: "/img/partner/pijar.png",
          link: "https://pijarmahir.id/category/kartu-prakerja?lp=776291",
        },
      ],
      requrementsPrakerjaProgram: [
        "WNI berusia paling rendah 18 tahun dan paling tinggi 64 tahun",
        "Tidak sedang menempuh pendidikan formal",
        "Sedang mencari kerja, pekerja/buruh yang terkena PHK, atau pekerja/buruh yang membutuhkan peningkatan kompetensi kerja, seperti pekerja/buruh yang dirumahkan dan pekerja",
        "bukan penerima upah, termasuk pelaku usaha mikro & kecil. <br/> Bukan Pejabat Negara, Pimpinan dan Anggota DPRD, Aparatur",
        "Sipil Negara, Prajurit TNI, Anggota Polri, Kepala Desa dan perangkat desa dan Direksi/Komisaris/Dewan Pengawas pada BUMN atau BUMD<br>Maksimal 2 NIK dalam 1 KK yang menjadi Penerima Kartu Prakerja",
      ],
      benefits: [
        {
          image: "/img/event/narasumber-profesional.png",
          title: "Instruktur Berpengalaman",
          desc: "Pelatihan akan dibimbing oleh instruktur yang berpengalaman di bidangnya",
        },
        {
          image: "/img/prakerja/ic_sertifikat_completion.png",
          title: "Learning Management System",
          desc: "Mendukung penuh kegiatan Kelas Kartu Prakerja berjalan optimal melalui fitur LMS",
        },
        {
          image: "/img/prakerja/ic_focus.png",
          title: "Materi Relevan & Mudah Dipahami",
          desc: "Materi pelatihan sesuai perkembangan industri saat ini & penyampaian mudah diterima peserta",
        },
        {
          image: "/img/prakerja/ic_sertifikat_kompetensi.png",
          title: "Penerbitan Sertifikat Aktual",
          desc: "Jaminan penerbitan Sertifikat Kartu Prakerja dilakukan setelah seluruh rangkaian mekanisme selesai",
        },
        {
          image: "/img/prakerja/ic_cs.png",
          title: "CS Responsif",
          desc: "Tim yang selalu siap melayani & merespon kebutuhan peserta",
        },
      ],
      listOfPrakerja: [],
      isModal: false,
      isPopup: false,
      isError: null,
      isLoading: false,
      confirmVoucher: false,
      codeVoucher: "",
      codeRedeem: "",
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
    };
  },
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  computed: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
  },
  methods: {
    async getClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class/category/4")
        .then((response) => {
          response.data.data.classes.forEach((element) => {
            this.listOfPrakerja.push({
              id: parseInt(element.id),
              name: element.name,
              image_url: element.thumbnail_url,
              description: element.description,
              level: element.level,
              slug_name: element.slug_name,
              instructor: element.instructor,
            });
          });
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
    async onCheckVaidVoucher() {
      this.isError = null;
      try {
        await this.$http.post(
          `/v1/voucher-prakerja?redeem_code=${this.codeRedeem}&voucher_code=${this.codeVoucher}`
        );
        sessionStorage.setItem("voucher_code", this.codeRedeem);
        sessionStorage.setItem("redeem_code", this.codeRedeem);
        this.confirmVoucher = true;
        this.isError = false;
      } catch {
        this.isError = true;
      }
    },
    async toCheckout(data) {
      this.isLoading = true;
      await this.$http
        .get("/v1/class-detail/" + data.slug_name)
        .then((response) => {
          const classDetail = response.data.data.class_detail;
          const reqCheckout = JSON.stringify({
            class_id: classDetail.class_id,
            class_name: classDetail.name,
            price: classDetail.price,
            level: classDetail.level,
            session: classDetail.session_total,
            hour: classDetail.hours,
            image_url: classDetail.small_image_url,
            class_type: "PRAKERJA_CLASS",
            days: classDetail.days,
            methode: classDetail.method,
            category_id: classDetail.category_id,
          });

          localStorage.clear();
          localStorage.setItem("checkout", reqCheckout);
          this.isLoading = false;
          window.location.href = "/checkout";
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err.response);
        });
    },
    openModalTukarVoucher() {
      if (this.confirmVoucher) {
        this.isPopup = true;
        return;
      }
      this.isModal = true;
    },
    goToPrakerja() {
      window.open(
        "https://www.prakerja.go.id/tentang-prakerja",
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    goTo(e) {
      window.open(
        e,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    handleView() {
      if (
        sessionStorage.getItem("voucher_code") &&
        sessionStorage.getItem("redeem_code")
      ) {
        this.conformVoucher = true;
        this.isError = false;
        this.codeVoucher = sessionStorage.getItem("voucher");
        this.codeRedeem = sessionStorage.getItem("redeem_code");
      }
    },
  },
  mounted() {
    this.handleView();
    this.getClass();
    this.$gtag.pageview(this.$route);
  },
};
</script>

<style src="./prakerja.css" scoped></style>